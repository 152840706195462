// eslint-disable-next-line import/named
import { html } from 'lit';
import { EventGetReference, EventSnippet, EventTemplate } from '../../components/ui/events';
import { MenuIconOption, PageControl, PageControlOptions, PageManager } from '../../components/ui/page-control';
import { DataEntryPageControlView, ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { DataEntryOwner } from '../../components/ui/DataEntryOwner';
import { PurchaseOrderContainer, PurchaseOrderContainerManager } from '../data/purchase-order-container';
import { tlang } from '@softtech/webmodule-components';
import { isAutoSaving } from '../../components/save-workflow';
import {
  PurchaseOrder,
  PurchaseOrderState,
  PurchaseOrderStateChangeReason
} from '../../api/dealer-api-interface-franchisee';
import { constructAsync } from '../../async-constructor';
import { PurchaseOrderDetailView, PurchaseOrderDetailViewOptions } from './purchase-order-detail-view';
import { DataCacheGeneric } from '../../cache/generic-data-cache';
import { DevelopmentError } from '../../development-error';
import { PurchaseOrderCacheData } from '../../dealer-franchisee/cache/cache-data';
import { DataCache } from '../../cache/data-cache';
import { NullPromise, Snippet } from '@softtech/webmodule-data-contracts';
import { flagInSet } from '../../components/ui/helper-functions';
import { customElement } from 'lit/decorators.js';

export interface PurchaseOrderViewOptions {
  title: EventSnippet;
  purchaseOrderContainerManager: PurchaseOrderContainerManager;
  purchaseOrderOwnerId: EventGetReference;
  purchaseOrderCache: DataCacheGeneric;
}

export interface PurchaseOrderViewChildFactory {
  getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView;
}

export class PurchaseOrderViewChildFactoryImpl implements PurchaseOrderViewChildFactory {
  parent: PurchaseOrderDataEntryView;

  constructor(parent: PurchaseOrderDataEntryView) {
    this.parent = parent;
  }

  getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView {
    return new PurchaseOrderDetailView(options);
  }
}
@customElement('wm-purchaseorderdataentryview')
export class PurchaseOrderDataEntryView extends DataEntryPageControlView {
  protected eventTitle: EventSnippet;
  protected purchaseOrderOwnerId: EventGetReference;
  protected purchaseOrderContainerManager: PurchaseOrderContainerManager;
  protected detailView: PurchaseOrderDetailView;
  protected purchaseOrderCache: DataCache<PurchaseOrderCacheData>;
  protected purchaseOrderViewChildFactory: PurchaseOrderViewChildFactory;
  intervalStateChange?: NodeJS.Timeout;

  constructor(options: PurchaseOrderViewOptions, owner?: DataEntryOwner) {
    super(owner);
    this.purchaseOrderViewChildFactory = this.getPurchaseOrderViewChildFactory();
    this.purchaseOrderContainerManager = options.purchaseOrderContainerManager;
    this.eventTitle = options.title;
    this.purchaseOrderOwnerId = options.purchaseOrderOwnerId;
    this.purchaseOrderCache = options.purchaseOrderCache;

    this.detailView = this.purchaseOrderViewChildFactory.getDetailView({
      purchaseOrderCache: this.purchaseOrderCache,
      purchaseOrderManager: this.purchaseOrderContainerManager
    });

    this.purchaseOrderContainerManager.afterSave.push(async () => {
      this.detailView.requestUpdate();
      this.requestUpdate();
    });
  }
  protected waitForStateChange() {
    const state = this.purchaseOrder.state;
    this.intervalStateChange = setInterval(async () => {
      if (this.purchaseOrderContainerManager.purchaseOrder.state !== PurchaseOrderState.IssuedPending) {
        clearInterval(this.intervalStateChange);
        this.intervalStateChange = undefined;
        return;
      }
      const data = await this.purchaseOrderContainerManager.getFreshOrderCopy();
      if (data && data.purchaseOrder.state !== state) {
        this.ui.dispatchEvent(
          new CustomEvent('resource-changed', {
            bubbles: true,
            composed: true,
            detail: {}
          })
        );
      }
    }, 60000);
  }
  async dispose() {
    await super.dispose();
    if (this.intervalStateChange) {
      clearInterval(this.intervalStateChange);
      this.intervalStateChange = undefined;
    }
  }

  public get purchaseOrder(): PurchaseOrder {
    if (!this.purchaseOrderContainer.purchaseOrder) throw new DevelopmentError('Purchase order container is null');
    return this.purchaseOrderContainer.purchaseOrder;
  }

  public get purchaseOrderContainer(): PurchaseOrderContainer {
    return this.purchaseOrderContainerManager.container;
  }
  public async afterConstruction() {
    await this.purchaseOrderContainerManager.needsPurchaseOrder();
    await constructAsync(this.detailView);
    await this.detailView.requestUpdate();

    //this will create the page control
    await super.afterConstruction();
    this.buildActionMenu();
    if (this.purchaseOrderContainerManager.purchaseOrder.state === PurchaseOrderState.IssuedPending)
      this.waitForStateChange();
  }

  public createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      pages.push(this.createDetailViewPage());
      return pages;
    };

    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: undefined,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  public override internalDataChanged(): boolean {
    return this.purchaseOrderContainerManager.changed();
  }

  public override getDataDictionaryName(): string {
    return tlang`%%purchase-order%%`;
  }

  public override isDataReadonly(): boolean {
    return this.purchaseOrderContainerManager.isReadOnly();
  }

  public getTitle(): Snippet {
    return this.eventTitle(this.purchaseOrderContainerManager.purchaseOrder);
  }

  public override async prepareForSave(): Promise<void> {
    if (this.isDataReadonly()) return;

    await this.detailView.prepareForSave();
  }

  protected getPurchaseOrderViewChildFactory(): PurchaseOrderViewChildFactory {
    return new PurchaseOrderViewChildFactoryImpl(this);
  }

  protected createDetailViewPage(): PageManager {
    return {
      caption: () => tlang`Details`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.detailView.invalidate();
      },
      content: () => {
        return this.detailView.ui;
      },
      buttonMenu: () => {
        return this.detailView.buttonMenu();
      },
      data: this.detailView
    };
  }

  protected bodyTemplate(): EventTemplate {
    this.buildActionMenu();
    return super.bodyTemplate();
  }

  protected override async internalSaveData(): Promise<boolean> {
    return this.purchaseOrderContainerManager.savePurchaseOrder(isAutoSaving());
  }

  protected override getValidationErrors(): string[] {
    return this.detailView.getValidationErrors();
  }

  protected async internalSetState(state: PurchaseOrderState): Promise<boolean> {
    const initialState = this.purchaseOrderContainerManager.purchaseOrder.state;
    this.purchaseOrderContainerManager.purchaseOrder.state = state;
    const saved = await this.performAutoSave();
    if (!saved) {
      this.purchaseOrderContainerManager.purchaseOrder.state = initialState;
    }
    return saved;
  }

  private buildActionMenu() {
    const menuIcons: MenuIconOption[] = [];
    if (!this._pageControl) return;

    const cancel = {
      caption: () => tlang`Cancel`,
      event: async () => {
        return await this.setState(PurchaseOrderState.Cancelled);
      },
      classList: 'btn btn-dark'
    };
    const issue: MenuIconOption = {
      caption: () => tlang`Issue`,
      disabled: !this.canIssue(),
      event: async () => {
        return await this.setState(PurchaseOrderState.IssuedPending);
      }
    };
    const complete = {
      caption: () => tlang`Complete`,
      event: async () => {
        return await this.setState(PurchaseOrderState.Completed);
      }
    };

    const save = {
      event: async () => {
        if (this.isDataReadonly()) return false;

        return await this.performAutoSave();
      },
      caption: () => tlang`Save`
    };
    const validateOrder = {
      event: async () => {
        if (this.isDataReadonly()) return false;

        try {
          await this.performQuoteValidation();
        } finally {
          await this.detailView.refreshData();
          this.dispatchUiChanged();
        }
        return true;
      },
      caption: () => tlang`Validate`
    };

    const status: MenuIconOption = {
      caption: () => tlang`Status`,
      childEvents: []
    };
    const pushValidStatusMenu = (...args) => {
      status.childEvents = args.filter(x => !x.disabled) ?? [];
      if (status.childEvents?.length > 0 && !this.isLockedFromUse) menuIcons.push(status);
    };
    switch (this.purchaseOrderContainerManager.purchaseOrder.state) {
      case PurchaseOrderState.Draft:
        pushValidStatusMenu(issue, cancel);
        break;
      case PurchaseOrderState.Issued:
        pushValidStatusMenu(complete, cancel);
        break;
    }

    if (!isAutoSaving() && !this.isLockedFromUse) menuIcons.push(save);
    if (flagInSet(this.purchaseOrder.state, PurchaseOrderState.Draft) && !this.canIssue() && !this.isLockedFromUse)
      menuIcons.push(validateOrder);
    this.pageControl.setMenuIcons(menuIcons);
  }
  async performQuoteValidation(): Promise<boolean> {
    return true;
  }
  canIssue(): boolean | undefined {
    return true;
  }

  protected get isLockedFromUse(): boolean {
    return this.purchaseOrderContainerManager.isLockedFromUse();
  }

  private async setState(state: PurchaseOrderState): Promise<boolean> {
    await this.prepareForSave();
    const result = this.internalSetState(state);
    this.requestUpdateAndPropagate();
    return result;
  }

  protected async getPurchaseOrderStateReasons(
    _purchaseOrderState: PurchaseOrderState
  ): NullPromise<PurchaseOrderStateChangeReason[]> {
    throw new Error('please overrride this method');
  }
}

@customElement('wm-purchaseorderview')
export class PurchaseOrderView extends ModalViewBase {
  view: PurchaseOrderDataEntryView | null = null;
  options: PurchaseOrderViewOptions;

  constructor(options: PurchaseOrderViewOptions) {
    super();
    this.options = options;
  }

  public override async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
  }

  override async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }

  protected override getTitle(): Snippet {
    return this.view?.getTitle() ?? '';
  }

  protected override modalSize(): string {
    return 'modal-fullscreen';
  }

  protected override renderFooterTemplate(): boolean {
    return false;
  }

  protected createView(): PurchaseOrderDataEntryView {
    return new PurchaseOrderDataEntryView(this.options, this);
  }

  protected bodyTemplate(): EventTemplate {
    return html`${this.view?.ui}`;
  }
}
