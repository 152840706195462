import { PropertyValueMap, html } from 'lit';
import { AsyncConstructor, constructAsync } from '../../async-constructor';

import { EventTemplate } from './events';
import { InformationDispatcher } from './information-dispatcher';
import { IDispose } from '../dispose';
import { LitElementBase } from '../litelement-base';
import { customElement, property } from 'lit/decorators.js';
import { awaitableTemplate } from './template-processor';
@customElement('wm-viewbase')
export class ViewBase extends LitElementBase implements AsyncConstructor, IDispose {
  informationDispatcher: InformationDispatcher = new InformationDispatcher();
  get ui(): HTMLElement {
    return this;
  }
  @property({ reflect: true }) class;
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('ui-changed', this.eventChildUIChanged);
    this.classList.add('div');
  }
  willUpdate(changedProperties) {
    if (changedProperties.has('class')) {
      this.classList.add('div');
    }
  }
  disconnectedCallback(): void {
    this.removeEventListener('ui-changed', this.eventChildUIChanged);
    this.dispose();
  }
  protected eventChildUIChanged = (_e: Event) => this.requestUpdate();
  protected requestUpdateAndPropagate() {
    this.requestUpdate();
    this.updateComplete.then(() => {
      this.dispatchUiChanged();
    });
  }

  protected dispatchUIChangedEventAfterUpdate() {
    this.updateComplete.then(() => {
      this.dispatchUiChanged();
    });
  }
  async dispatchInformation(info: string) {
    await this.informationDispatcher.setInformation(info);
  }
  eventPrefix() {
    return 'wm-event';
  }
  protected async firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>) {
    await constructAsync(this);
    this.requestUpdate(); //incase construction needs a rebuild
  }
  dispatchCustom<T>(name: string, values: T) {
    const options = {
      detail: values,
      bubbles: true,
      composed: true
    };
    const eventName = name.startsWith('!') ? name.substring(1) : `${this.eventPrefix()}-${name}`;
    this.ui.dispatchEvent(new CustomEvent(eventName, options));
    return values;
  }
  async afterConstruction(): Promise<void> {
    //Nothing to do
  }
  protected template(): EventTemplate {
    return html``;
  }
  protected render() {
    return awaitableTemplate(this.template());
  }

  protected async postRender(): Promise<void> {
    //override if needed
  }
  dispatchUiChanged() {
    this.dispatchCustom('!ui-changed', { ui: this.ui });
  }
  async dispose() {
    //override if needed
  }
}
