import { css, html, LitElement } from 'lit';
import { state } from 'lit/decorators.js';

import { customElement } from 'lit/decorators.js';
import { WebmoduleIcon } from '@softtech/webmodule-components';
import { classMap } from 'lit/directives/class-map.js';

declare global {
  interface DocumentEventMap {
    'webmodule-auto-save': CustomEvent<AutoSaveNotification>;
  }
}

export class AutoSaveNotifier {
  private static instance: AutoSaveNotifier;

  private constructor() {}

  public static getInstance(): AutoSaveNotifier {
    if (!AutoSaveNotifier.instance) {
      AutoSaveNotifier.instance = new AutoSaveNotifier();
    }
    return AutoSaveNotifier.instance;
  }

  public triggerAutoSave(type: 'busy' | 'success' | 'error', timeout = 2000) {
    const event = new CustomEvent<AutoSaveNotification>('webmodule-auto-save', {
      detail: {
        type: type,
        timeout: timeout
      },
      bubbles: true,
      composed: true
    });
    document.dispatchEvent(event);
  }

  public triggerAutoSaveBusy() {
    this.triggerAutoSave('busy', -1);
  }

  public triggerAutoSaveSuccess() {
    this.triggerAutoSave('success');
  }

  public triggerAutoSaveError() {
    this.triggerAutoSave('error', 2000);
  }
}

export const autoSaveNotifier = AutoSaveNotifier.getInstance();

export interface AutoSaveNotification {
  type: 'busy' | 'success' | 'error' | null;
  timeout?: number;
}

//TODO: Once styling is done, move to lit components repo
@customElement('wm-webmoduleautosaveindicator')
export class WebmoduleAutoSaveIndicator extends LitElement {
  static styles = css`
    :host {
      display: inline-block;
      position: relative;
    }

    .indicator {
      display: none;
      font-size: 18px;
    }

    .indicator--visible {
      font-size: 18px;
      display: inline-block;
    }

    .indicator--busy {
      color: var(--bs-info);
    }

    .indicator--success {
      color: var(--bs-success);
    }

    .indicator--danger {
      color: var(--bs-danger);
    }
  `;

  static dependencies = {
    'webmodule-icon': WebmoduleIcon
  };

  @state()
  private _type: 'busy' | 'success' | 'error' | 'idle' | null;
  private _timer?: NodeJS.Timeout;

  constructor() {
    super();

    this._type = 'idle';
  }

  //TODO: Should we respond to the event here, or rather have the parent element set properties to render.
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('webmodule-auto-save', this.eventHandleAutoSave);
  }

  disconnectedCallback() {
    document.removeEventListener('webmodule-auto-save', this.eventHandleAutoSave);
    super.disconnectedCallback();
  }

  eventHandleAutoSave = (event: CustomEvent<AutoSaveNotification>) => {
    this._type = event.detail.type;

    const timeout = event.detail.timeout ?? 2000;
    if (this._timer) clearTimeout(this._timer);
    if (timeout > -1)
      this._timer = setTimeout(() => {
        this._type = 'idle';
        this._timer = undefined;
      }, timeout);
  };
  private get visible(): boolean {
    return this._type !== 'idle';
  }
  render() {
    let icon = html``;

    switch (this._type) {
      case 'busy':
        icon = html`<webmodule-icon library="system" name="saving-in-progress"></webmodule-icon>`;
        break;
      case 'success':
        icon = html`<webmodule-icon library="system" name="saved"></webmodule-icon>`;
        break;
      case 'error':
        icon = html`<webmodule-icon library="system" name="error-save"></webmodule-icon>`;
        break;
    }

    return html`
      <div
        class="${classMap({
          indicator: true,
          'indicator--visible': this.visible,
          'indicator--busy': this._type === 'busy',
          'indicator--success': this._type === 'success',
          'indicator--danger': this._type === 'error'
        })}"
      >
        ${icon}
      </div>
    `;
  }
}
