import {
  // eslint-disable-next-line import/named
  PreventAndRedirectCommands,
  // eslint-disable-next-line import/named
  PreventCommands,
  Router,
  RouterLocation
} from '@vaadin/router';
// eslint-disable-next-line import/named
import { LitElement, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';

import { router } from '../../../components/router';
import { NullPromise } from '../../../null-promise';
import { userDataStore } from '../../common/current-user-data-store';
import { MetaOptions, updateMeta } from './meta-manager';
import {
  CurrentUserStateNotifier,
  UserPublicInfo,
  addCurrentUserEventListener,
  getCurrentUser,
  removeCurrentUserEventListener
} from '@softtech/webmodule-components';
import { IDispose } from '../../../components/dispose';
import { appOutOfDate, checkAppOutOfDate, isDebugMode, outOfDateURL } from '../../../components/debug';
import { goStaticURL } from '../../../components/ui/resource-resolver';

export interface IPageController {
  disposeAndRefresh();
  pagesCanClose: () => Promise<boolean>;
}
interface IPageRefresh {
  disposeAndRefresh: () => Promise<void>;
  pageCanClose: () => Promise<boolean>;
}

class PageController implements IPageController {
  constructor() {
    setInterval(
      async () => {
        await checkAppOutOfDate();
        if (appOutOfDate()) goStaticURL(outOfDateURL);
      },
      isDebugMode() ? 1000 * 60 : 1000 * 60 * 60
    );
  }
  pages: IPageRefresh[] = [];
  addPage(page: IPageRefresh) {
    if (!this.pages.find(x => x == page)) this.pages.push(page);
  }
  remPage(page: IPageRefresh) {
    this.pages = this.pages.filter(x => x !== page);
  }
  async disposeAndRefresh() {
    for (let i = 0; i < this.pages.length; i++) await this.pages[i].disposeAndRefresh();
  }
  async pagesCanClose(): Promise<boolean> {
    let canClose = true;
    for (let i = 0; i < this.pages.length; i++) canClose = (await this.pages[i].pageCanClose()) && canClose;
    return canClose;
  }
}
const pageController = new PageController();

export function getPageController(): IPageController {
  return pageController;
  //allow refreshing
}
export class PageBase extends LitElement implements CurrentUserStateNotifier, IPageRefresh, IDispose {
  @property({ type: Object })
  protected location = router.location;

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected get loggedIn(): boolean {
    return getCurrentUser() !== null;
  }

  private defaultTitleTemplate = `%s | Dealer's Module`;

  protected get defaultMeta() {
    return {
      url: window.location.href,
      titleTemplate: this.defaultTitleTemplate
    };
  }

  protected divertToLogin(): boolean {
    return true;
  }
  protected divertToOutOfDate(): boolean {
    return true;
  }
  async onBeforeEnter(_location: RouterLocation, commands: PreventAndRedirectCommands, _router: Router): Promise<any> {
    if (appOutOfDate() && this.divertToOutOfDate()) {
      return commands.redirect(outOfDateURL);
    }
    if (!this.loggedIn && this.divertToLogin()) {
      return commands.redirect('/login');
    }

    const allowEnter = await this.allowEnter();
    if (!allowEnter) {
      const r = await this.redirectPath();
      if (r) return commands.redirect(r);
      return commands.prevent();
    }
    pageController.addPage(this);
  }
  async redirectPath(): NullPromise<string> {
    return null;
  }
  /***
   * overriding this can be used as a preconstruction technique that can fail if for some reason the page
   * cannot be accessed.. or redirected.
   */
  protected async allowEnter(): Promise<boolean> {
    return true;
  }

  async pageCanClose(): Promise<boolean> {
    if (this.loggedIn) return await this.canLeavePage();
    return true;
  }

  async onBeforeLeave(_location: RouterLocation, commands: PreventCommands, _router: Router) {
    if (appOutOfDate()) return true;
    if (this.loggedIn)
      if (!(await this.canLeavePage())) {
        //if not logged in, we cant do anything, we are trying to exit cleanly
        return commands.prevent();
      }
    pageController.remPage(this);
    return true;
  }
  protected async canLeavePage(): Promise<boolean> {
    return true;
  }

  public async onAfterEnter(
    _location: RouterLocation,
    _commands: PreventAndRedirectCommands,
    _router: Router
  ): Promise<void> {
    const event = new CustomEvent('webmodule-page-navigation', {
      bubbles: true,
      composed: true,
      detail: { name: _location.route?.name, path: _location.route?.path }
    });
    this.dispatchEvent(event);
    await this.awaken();
  }

  protected async awaken() {
    //do stuff as preload.
  }

  public async onAfterLeave(
    _location: RouterLocation,
    _commands: PreventAndRedirectCommands,
    _router: Router
  ): Promise<void> {
    if (this.loggedIn) {
      //if we are not logged in, then anything we do here is probably going to fail as we are doing a page reload.
      await this.dispose();
    }
  }
  public async disposeAndRefresh() {
    await this.dispose();
    await this.afterUserConnected();
  }
  public async dispose() {
    //do shutdown stuff
  }

  /**
   * The page must override this method to customize the meta
   */
  protected meta(): MetaOptions | undefined {
    return;
  }
  async userReset() {
    await this.dispose();
  }
  async userStateChanged(user: UserPublicInfo | null): Promise<void> {
    if (appOutOfDate()) return;
    if (user !== null) {
      await userDataStore.loadCoreDetails();

      await this.afterUserConnected();
    } else {
      await this.userReset();
    }
    this.requestUpdate();
  }
  protected async afterUserConnected() {
    //
  }
  protected eventChildUIChanged = (_e: Event) => this.requestUpdate();
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('ui-changed', this.eventChildUIChanged);
    addCurrentUserEventListener(this);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('ui-changed', this.eventChildUIChanged);
    removeCurrentUserEventListener(this);
  }
  updated(changedProperties: PropertyValues<this>) {
    super.updated(changedProperties);

    const meta = this.meta();

    if (meta) {
      updateMeta({
        ...this.defaultMeta,
        ...((meta.titleTemplate || meta.titleTemplate === null) && {
          titleTemplate: meta.titleTemplate
        }),
        ...meta
      });
    }
  }
}
