/* eslint-disable @typescript-eslint/no-explicit-any */
//this is a generic base layer for creating a datatables table that will interact with
//a server to process and show paginatedresults of different class types
// eslint-disable-next-line import/named
// noinspection HtmlUnknownAttribute
// eslint-disable-next-line import/named
import { html, PropertyValueMap, TemplateResult } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { lapsedMinutes } from '../time';
import { TimedTrigger } from '../../timed-trigger';
import { lockUIandExecute } from '../../ui-lock';
import { LitElementBase } from '../litelement-base';
import { property, query } from 'lit/decorators.js';

export interface ResultPaginated<RowClass> {
  count: number;
  pageCount: number;
  pageSize: number;
  pageIndex: number;
  //Note. remove the Null as soon as null is removed from the generated code so that they are compatible
  results: RowClass[] | null;
}

export interface RequestPage {
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortAsc: boolean;
}

export type HTMLElementProvider = () => HTMLElement;

/*this lets us easily bypass the typescript jquery definitions which are not what we want to use for datatables as the
typescript is out of date.
DataTables must be added to the main page directly, not compiled in*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const $j = $.fn as any;

//this class will build everything into its own div, which may then be embedded into a container.
export class DataTableWrapper<RowClass> extends LitElementBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataTable: any | null = null;

  lastRefresh: Date = new Date();
  timedTrigger!: TimedTrigger | undefined;
  filterValue: string | null = null;
  @query('.dataTables_scrollBody')
  scrollBody?: Element | null;
  @query('.data-table')
  dataTableElement?: Element | null;

  constructor() {
    super();
    this.initTimedTrigger();
  }

  @property({ reflect: true }) class;
  connectedCallback(): void {
    super.connectedCallback();

    this.classList.add('div');
  }
  willUpdate(changedProperties) {
    if (changedProperties.has('class')) {
      this.classList.add('div');
    }
  }

  private initTimedTrigger() {
    const triggerEvent = e => {
      this.filterChangeEvent(e);
    };
    this.timedTrigger = new TimedTrigger(1000, triggerEvent);
  }
  htmlEncode(value: string): string {
    return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties);
    this.connectDataTable();
    const filteredEnabledClass = this.enableFiltering() ? `webmodule-filter-enabled` : ``;
    if (this.scrollBody && filteredEnabledClass !== '') {
      this.scrollBody?.classList.add(filteredEnabledClass);
    }
  }
  //probably only needs to be called a single time, as after that the datatable is self manipulating.

  //build and create the datatable.
  connectDataTable() {
    const dataTableElement = this.dataTableElement;
    if (dataTableElement) {
      const $dataTable = $(dataTableElement) as any;
      if (!$j.dataTable.isDataTable($dataTable)) {
        //lets build the datatable
        const columns = this.getColumns();
        const rowGroup = this.getRowGroup();
        const sortFieldIndex: number = this.getDefaultSortFieldIndex(columns);
        const sortAsc: string = this.getDefaultSortAsc() ? 'asc' : 'desc';

        this.dataTable = $dataTable.DataTable({
          createdRow: (row, data, dataIndex) => {
            this.afterRowCreated(row, data, dataIndex);
          },
          // scrollX: true,
          // scrollY: this.verticalHeight(),
          // scrollCollapse: true,
          ordering: this.ordering(),
          paging: this.usePaging(),
          order: [[sortFieldIndex, sortAsc]],
          pageLength: this.pageLength(),
          lengthChange: false,
          processing: true,
          serverSide: true,
          ajax: (request: any, drawCallback, settings) => this.getPageData(request, drawCallback, settings),
          //data: dataset,
          searching: false,
          info: this.useInfo(),
          columns: columns,
          rowGroup: rowGroup,
          //fixedColumns: { left: 1 },
          autoWidth: this.useAutoWidthColumns()
        });
        this.dataTable.columns.adjust();
        this.bindClickEvents($dataTable);
        //bind in click events an other things here.
      } else {
        this.dataTable = $dataTable.DataTable();
        this.dataTable.rows().invalidate();
      }
    } else throw new Error("could not bind 'data-table'");
  }
  afterRowCreated(_row: any, _data: any, _dataIndex: any) {
    //throw new Error("Method not implemented.");
  }
  protected ordering(): boolean {
    return true;
  }

  /*this must be overridden with the specific api calls to the server to get the data,
    and transform it to the appropriate result type required.*/
  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<RowClass>> {
    return {
      count: 0,
      pageCount: 0,
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      results: []
    };
  }

  getDefaultSortAsc(): boolean {
    //override as needed
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getDefaultSortFieldIndex(_columns: any[]): number {
    //override is required
    return 0;
  }

  pageLength(): number {
    //override as needed
    return 200;
  }

  usePaging(): boolean {
    //override as needed
    return true;
  }
  useInfo(): boolean {
    //override as needed
    return true;
  }

  enableFiltering(): boolean {
    //override as needed
    return false;
  }

  updateFilter(_searchTerm: string | null) {
    //Override as needed
    return;
  }

  enableAdvancedFiltering(): boolean {
    //override as needed
    return false;
  }

  /*override as needed. this sets the vertical height of the grid, and must be a fixed size. the grid will scroll internally
    to deal with extra data*/
  verticalHeight(): string | undefined {
    return '50vh';
  }

  /*override as needed. if this is changed to false
     then the grid template needs to set a width that exactly matches the width of all columns,
     otherwise horizontal scrolling does not work correctly*/
  useAutoWidthColumns(): boolean {
    return false;
  }

  getRowGroup(): any {
    /*
         datatable rowGroup definition
         {
         dataSrc: 'colName' | ['colName1', 'colName2']
         }
         */
    return false;
  }

  //must override and provide a correct set of columns, which will render based on the RowType class fields
  getColumns(): any[] {
    /* // datatable column definitions
         {

         title: '<i class="fas fa-star"></i>',
         width: "40px",
         data: "windowId",
         render: (value) => {
         const favClassVal = (this.isFavourite(value) ? "fas " : "far ") + " fa-star text-warning favourite ";
         const jobClassVal = (this.isJob(value) ? "text-success " : "text-secondary ") + " fas fa-home  job";
         const val = `<i class="${favClassVal}" ></i> <i class="${jobClassVal}"  ></i>`;
         return val;
         }
         },
         */
    return [];
  }

  public filterTemplate(): TemplateResult {
    const resetEvent = this.timedTrigger?.getResetEvent();
    const triggerEvent = this.timedTrigger?.getTriggerEarlyEvent();
    const advancedFilterTemplate = this.enableAdvancedFiltering()
      ? html` <div class="col-7 filter-advanced">${this.advancedFilterTemplate()}</div>`
      : html``;

    return !this.enableFiltering()
      ? html``
      : html`
          <div class="row form-group filter-wrapper">
            ${advancedFilterTemplate}
            <div class="col-1 filter-text">Filter:</div>
            <div class="col-4 filter-input">
              <input
                class="form-control"
                placeholder="Text Search"
                @oninput=${resetEvent}
                @blur=${triggerEvent}
                @keyup=${resetEvent}
              />
            </div>
          </div>
        `;
  }

  public advancedFilterTemplate(): TemplateResult {
    //override as needed
    return html``;
  }

  public render() {
    const autoSize = this.useAutoWidthColumns();
    let style = ``;

    //if we are not autosizing, and there is a chance that we have overflow, or other issues
    //we need to set the width of the table to be exactly the width of all columns
    if (!autoSize) {
      let width = 0;
      const getWidth = (w: string) => {
        const num = parseInt(w.slice(0, w.length - 2));
        return isNaN(num) ? 100 : num;
      };
      this.getColumns().forEach(value => (width += getWidth(value.width)));
      style = `style="width:${width}px"`;
    }

    const unsafe = `<table class="data-table table cell-border ${this.enableTableClass()}" ${style}>`;
    return html`
            ${this.filterTemplate()}
            ${unsafeHTML(unsafe)}
            <thead>
            </thead>
            <tbody></tbody>
            </table>
        `;
  }

  public forceRefresh() {
    this.lastRefresh = new Date('2000-01-01');
  }
  /*this routine should be called anytime something external to the base class has changed which would impact on the content
    of a page of data as supplied by the server.
    examples of this are external filters etc which might change and need to trigger a reload
    this always resets the page back to the first page, as the current page may no longer exist based on the changes.*/
  public async refreshData(sinceLastRefreshMinutes?: number): Promise<void> {
    if (this.dataTable && (!sinceLastRefreshMinutes || lapsedMinutes(this.lastRefresh) >= sinceLastRefreshMinutes))
      return new Promise<void>(resolve => {
        this.dataTable.ajax.reload(resolve, true);
      }).then(() => this.requestUpdate());

    this.requestUpdate();
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected bindClickEvents(_$dataTable: any) {
    //override;
  }

  public invalidate() {
    this.dataTable?.rows().invalidate();
    this.requestUpdate();
  }
  /*do not touch or override this. this is a wrapper between our application api and the datatables interface
          This can be enhanced to utilize page caching if we desire.*/
  private getPageData(request: any, drawCallback: any, settings: any) {
    const sortFieldIndex: number = this.getDefaultSortFieldIndex(settings.aoColumns);
    let sortField: string = settings.aoColumns[sortFieldIndex].data;
    let sortAsc: boolean = this.getDefaultSortAsc();
    if (request.order?.length > 0) {
      sortField = settings.aoColumns[request.order[0].column ?? 0].data ?? sortField;
      sortAsc = request.order[0].dir === 'asc';
    }
    const pageIndex = request.start / request.length;
    this.getRowsFromServer({
      pageIndex: pageIndex,
      pageSize: this.pageLength(),
      sortField: sortField,
      sortAsc: sortAsc
    }).then(results => {
      this.lastRefresh = new Date();
      drawCallback({
        draw: request.draw,
        recordsTotal: results.count,
        recordsFiltered: results.count,
        data: results.results
      });
    });
  }

  private async filterChangeEvent(e: Event) {
    const searchTerm = (e.target as HTMLInputElement).value;

    this.updateFilter(searchTerm);

    await this.refreshData();
  }

  protected eventHandler(callback: (data: RowClass, ev: Event, row: any, tablerow: any) => Promise<void>) {
    return dataTableRowClickEventHandler(this.dataTable, callback);
  }

  protected enableTableClass(): string {
    //override
    return '';
  }
}

export function dataTableRowClickEventHandler<TRow>(
  dataTable: any,
  callBack: (data: TRow, ev: Event, row: any, tablerow: any) => Promise<void>
) {
  return async (ev: Event) => {
    //cancel any propagation of href etc etc.
    ev.stopPropagation();
    ev.preventDefault();
    if (ev.target !== null) {
      //locate the row data
      const tr = ($(ev.target) as any).parents('tr');
      const row = dataTable.row(tr);
      const data = row.data() as TRow;
      if (data) {
        await lockUIandExecute(async () => await callBack(data, ev, row, tr));
      }
    }
  };
}
