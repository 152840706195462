/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/named
import { html } from 'lit';
import { emptyGuid, newGuid } from '../../api/guid';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/datatable-view';

import { PageControl, PageControlOptions, PageManager } from '../../components/ui/page-control';
import { InputCreateProject, ProjectState, ViewProjectSummary } from '../../api/dealer-api-interface-project';
import { ProjectApi } from '../../api/project-api';
import { EventGetReference, EventSnippet, EventTemplate } from '../../components/ui/events';
import { NullPromise } from '../../null-promise';
// eslint-disable-next-line import/named
import { IconRefresh } from '../../components/ui/icons/icon-refresh';
import { ProjectContainer } from '../data/project-container';
import { getInternalId } from '../../components/ui/databinding/databinding';
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { getProjectNumberFormatted } from '../../dealer-franchisee/projects/data/project-helper-functions';
import { localDateToServer, today } from '../../components/datetime-converter';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { showError } from '../../components/ui/show-error';
import { DataCacheGeneric } from '../../cache/generic-data-cache';
import { launchProject, resourceProject } from '../ui/launcher';
import { ViewBase } from '../../components/ui/view-base';
import { PurchaseOrderCacheData } from '../../dealer-franchisee/cache/cache-data';
import { DataCache } from '../../cache/data-cache';
import { emptyAddress } from '../../components/ui/maps/map-helpers';
import { resolveURL } from '../../components/ui/resource-resolver';
import { resourceClient } from '../../dealer-franchisee/clients/ui/launcher';
import { customElement } from 'lit/decorators.js';

const minutesToRefresh = 2;

interface ProjectSummaryTableOptions {
  projectOwnerId: EventGetReference;
  projectStates?: ProjectState;
  clientCache: DataCacheGeneric;
  userCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  title: EventSnippet;
  pageFragment: string;
}
@customElement('wm-projectsummarytable')
export class ProjectSummaryTable extends DataTableWrapper<ViewProjectSummary> {
  eventTitle: EventSnippet;
  projectApi: ProjectApi = getApiFactory().project();
  projectOwnerId: EventGetReference;
  projectStates: ProjectState;
  clientCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  userCache: DataCacheGeneric;
  filter: string | null;
  pageFragment: string;

  constructor(options: ProjectSummaryTableOptions) {
    super();
    this.eventTitle = options.title;
    this.projectStates = options.projectStates ?? ProjectState.None;
    this.clientCache = options.clientCache;
    this.projectOwnerId = options.projectOwnerId;
    this.userCache = options.userCache;
    this.purchaseOrderCache = options.purchaseOrderCache;
    this.filter = null;
    this.pageFragment = options.pageFragment;
  }

  override updateFilter(_searchTerm: string | null) {
    this.filter = _searchTerm;
  }

  override enableFiltering(): boolean {
    return true;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewProjectSummary>> {
    const results = await this.projectApi.browseProjectSummary({
      //projectOwnerId: await this.projectOwnerId(),
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      statesToLoad: this.projectStates,
      filter: this.filter,
      sortField: request.sortField,
      sortAsc: request.sortAsc,
      createdById: null,
      projectOwnerId: await this.projectOwnerId()
    });
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const clientKeys = results.projectSummary.results!.map(x => x.clientId);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userKeys = results.projectSummary.results!.map(x => x.assignedToUserId);

    //if there are any other callbacks we need to do, add them to the
    //all array which will let them run at the same time
    await Promise.all([this.clientCache.preFetch(clientKeys), this.userCache.preFetch(userKeys)]);

    return results.projectSummary;
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  getDefaultSortFieldIndex(columns: any[]): number {
    return columns.findIndex(c => c.data === 'number');
  }

  getProjectLink(id: string, displayText: string): string {
    return `<a class="project-link" data-project-id=${id} href="${resolveURL(resourceProject, id)}">${displayText}</a>`;
  }
  getClientLink(id: string, displayText: string): string {
    return `<a class="client-link" data-client-id=${id} href="${resolveURL(resourceClient, id)}">${displayText}</a>`;
  }
  getColumns(): any[] {
    return [
      {
        title: tlang`%%project%% No.`,
        width: '90px',
        data: 'number',
        className: 'project-number',
        render: (_data: number, _type: string, row: ViewProjectSummary) => {
          return this.getProjectLink(row.id, getProjectNumberFormatted(row));
        }
      },
      {
        title: tlang`%%project%% Title`,
        width: '400px',
        data: 'title',
        className: 'project-title',
        render: (_data: string, _type: string, row: ViewProjectSummary) => {
          return this.getProjectLink(row.id, row.title);
        }
      },
      {
        title: tlang`%%client%%`,
        width: '200px',
        data: 'clientId',
        className: 'project-client',
        orderable: false,
        render: (clientId: string) => {
          return this.getClientLink(clientId, this.getClientDisplayValue(clientId));
        }
      },
      {
        title: tlang`Author`,
        width: '200px',
        data: 'assignedToUserId',
        className: 'project-client-name',
        orderable: false,
        render: (value: string) => this.getUserDisplayValue(value)
      }
    ];
  }

  getUserDisplayValue(value: string) {
    if (value == emptyGuid) return '';
    //prefetch should already have been called
    return this.userCache.getLocal(value)?.displayValue ?? '';
  }

  private getClientDisplayValue(value: string) {
    if (value == emptyGuid) return '';
    //prefetch should already have been called
    return this.clientCache.getLocal(value)?.displayValue ?? '';
  }
}

export interface ProjectListViewOptions {
  projectOwnerId: EventGetReference;
  clientCache: DataCacheGeneric;
  contactCache: DataCacheGeneric;
  paymentProfileCache: DataCacheGeneric;
  userProfileCache: DataCacheGeneric;
  quoteCache: DataCacheGeneric;
  purchaseOrderCache: DataCache<PurchaseOrderCacheData>;
}
@customElement('wm-projectlistview')
export class ProjectListView extends ViewBase {
  tables: ProjectSummaryTable[];

  elementId: string;
  clientCache: DataCacheGeneric;
  contactCache: DataCacheGeneric;
  clientTypeCache: DataCacheGeneric;
  userProfileCache: DataCacheGeneric;
  pageControl: PageControl;
  projectOwnerId: EventGetReference;
  projectApi: ProjectApi = getApiFactory().project();
  quoteCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  constructor(options: ProjectListViewOptions) {
    super();
    this.elementId = `project-list-view-${getInternalId()}`;
    this.projectOwnerId = options.projectOwnerId;
    this.clientCache = options.clientCache;
    this.contactCache = options.contactCache;
    this.clientTypeCache = options.paymentProfileCache;
    this.userProfileCache = options.userProfileCache;
    this.quoteCache = options.quoteCache;

    this.purchaseOrderCache = options.purchaseOrderCache;
    this.tables = this.initializeTables();
    this.pageControl = this.createPageControl();
  }
  public async setActiveTabByHash() {
    await this.pageControl?.applyWindowHash();
  }
  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      return this.tables.map(table => {
        const pm: PageManager = {
          caption: table.eventTitle,
          canClose: () => Promise.resolve(false),
          canLeave: () => Promise.resolve(true),
          hasDelete: () => false,
          onEnter: async () => {
            // not-awaiting on purpose, for faster page switch. safe to do
            table.refreshData(minutesToRefresh);
          },
          content: () => {
            return table;
          },
          data: table,
          pageFragment: table.pageFragment
        };
        return pm;
      });
    };

    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: [
        {
          event: async () => {
            const activePage = this.pageControl.activePage;

            if (activePage) {
              const table = activePage.data as ProjectSummaryTable;
              await table.refreshData();
            }

            return true;
          },
          caption: () => html`${new IconRefresh()}`
        }
      ],
      plusPage: {
        caption: () => tlang`Create %%project%%`,
        event: async () => await this.addProject()
      },
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  protected async createNewProject(): NullPromise<ProjectContainer> {
    const owner = await this.projectOwnerId();
    if (!(await userDataStore.singleSupplierTACApproved())) return null;
    if (!userDataStore.defaultBranch) await userDataStore.loadCoreDetails();
    if (!userDataStore.defaultBranch) {
      await showError(
        tlang`Cannot create %%project%% because you are not assigned to a %%branch%%, please contact support`
      );
      return null;
    }

    //TODO: don't default project address to franchisee for now, we'll revisit this later in task 213557
    const addressToUse = emptyAddress();

    if (!isEmptyOrSpace(owner)) {
      try {
        const inputCreateProject: InputCreateProject = {
          project: {
            title: tlang`New %%project%%`,
            budget: 0,
            id: newGuid(), //change this to use server side guid fetch
            number: 0,
            clientId: emptyGuid,
            description: null,
            recordVersion: '',
            dateCreated: localDateToServer(today()),
            state: ProjectState.Active,
            clientTypeId: emptyGuid,
            contactId: emptyGuid,
            lastModifiedDate: localDateToServer(today()),
            creationUserId: emptyGuid, //set on server side
            defaultAddress: addressToUse,
            shipToDefaultAddress: false,
            lastModifiedUserId: emptyGuid,
            shippingNotes: null,
            projectOwnerId: owner,
            assignedToUserId: emptyGuid // set on server side
          },
          numberSeed: owner,
          projectReferences: []
        };

        const result = await this.projectApi.createProject(inputCreateProject);

        if (!result) return null;

        //construct new project container for an empty new project
        return new ProjectContainer(result.project.id, result.project, result.resources, result.documents);
      } catch {
        return null;
      }
    } else {
      //owner is defined as the Branch of the user.
      await showError(
        tlang`Cannot create %%project%% because you are not assigned to a %%branch%%, please contact support`
      );
      return null;
    }
  }

  protected async addProject(): Promise<boolean> {
    const project = await this.createNewProject();
    if (project) {
      launchProject(project.projectId);
      return true;
    }
    return false;
  }

  protected createSummaryTable = (
    title: EventSnippet,
    projectState: ProjectState,
    pageFragment: string
  ): ProjectSummaryTable => {
    return new ProjectSummaryTable({
      projectStates: projectState,
      clientCache: this.clientCache,
      userCache: this.userProfileCache,
      purchaseOrderCache: this.purchaseOrderCache,
      title: title,
      projectOwnerId: this.projectOwnerId,
      pageFragment: pageFragment
    });
  };

  private initializeTables(): ProjectSummaryTable[] {
    return [
      this.createSummaryTable(() => tlang`Active`, ProjectState.Active, 'active'),
      this.createSummaryTable(() => tlang`Completed`, ProjectState.Completed, 'completed'),
      this.createSummaryTable(() => tlang`Cancelled`, ProjectState.Cancelled, 'cancelled')
    ];
  }

  protected template(): EventTemplate {
    return html` <div id=${this.elementId} class="page-content">${this.pageControl.ui}</div>`;
  }
}
