import { customElement } from 'lit/decorators.js';
import { PurchaseOrderListView } from '../../../purchase-orders/views/purchase-order-list-view';
import {
  PurchaseOrderSummaryTable,
  PurchaseOrderSummaryTableOptions
} from '../../../purchase-orders/views/purchase-order-summary-table';
import { FranchiseePurchaseOrderSummaryTable } from './franchisee-purchase-order-summary-table';

@customElement('wm-franchiseepurchaseorderlistview')
export class FranchiseePurchaseOrderListView extends PurchaseOrderListView {
  //this can be overridden in subclass to replace the table with different column/view etc
  protected purchaseOrderSummaryTableFactory(options: PurchaseOrderSummaryTableOptions): PurchaseOrderSummaryTable {
    return new FranchiseePurchaseOrderSummaryTable(options);
  }
}
