//this will contain the class which is a table browser paginated view
//for quotes for quote-owner, and potentially other filters hardwired
//such as state, for a multi tabbed view
// eslint-disable-next-line import/named
import { html } from 'lit';
import { QuoteState, ResultGetQuoteSummary, ViewQuoteSummary } from '../../api/dealer-api-interface-quote';
import { emptyGuid } from '../../api/guid';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/datatable-view';
import { QuoteApi } from '../../api/quote-api';
import { PageControl, PageControlOptions, PageManager } from '../../components/ui/page-control';
import { BlobApi } from '../../api/blob-api';
import { QuoteView, QuoteViewOptions } from './quote-view';
import { NullPromise } from '../../null-promise';
import { EventGetReference, EventSnippet, EventTemplate } from '../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { ClientApi } from '../../api/client-api';
import { IconRefresh } from '../../components/ui/icons/icon-refresh';
import { QuoteContainer, QuoteContainerManager } from '../data/quote-container';
import { getInternalId } from '../../components/ui/databinding/databinding';
import { getApiFactory } from '../../api/api-injector';
import { moneyToHtml } from '../../components/currency-formatter';
import { DataCacheGeneric } from '../../cache/generic-data-cache';
import { launchQuote, resourceQuote } from '../ui/launcher';
import { ViewBase } from '../../components/ui/view-base';
import { resourceClient } from '../../dealer-franchisee/clients/ui/launcher';

import { resolveURL } from '../../components/ui/resource-resolver';
import { customElement } from 'lit/decorators.js';

const minutesToRefresh = 3;

export type EventQuote = (quoteSummary: ViewQuoteSummary) => Promise<void>;

export type EventLink = (linkId: string) => Promise<void>;

export interface QuoteSummaryTableOptions {
  quoteOwnerId: EventGetReference;
  numberOfDaysHistory?: number;
  quoteStates?: QuoteState;
  customerCache: DataCacheGeneric;
  userProfileCache: DataCacheGeneric;
  title: EventSnippet;
  copyQuoteEvent: EventQuote;
  deleteQuoteEvent: EventQuote;
  pageFragment: string;
}

//Basic class for displaying quotes in a table form
@customElement('wm-quotesummarytable')
export class QuoteSummaryTable extends DataTableWrapper<ViewQuoteSummary> {
  eventTitle: EventSnippet;
  api: QuoteApi = getApiFactory().quote();
  quoteOwnerId: EventGetReference;
  quoteStates: QuoteState;
  customerCache: DataCacheGeneric;
  userCache: DataCacheGeneric;
  copyQuoteEvent: EventQuote;
  deleteQuoteEvent: EventQuote;

  private titleFilter: string | null;
  protected assignedToUserId: string | null = null;
  numberOfDaysHistory: number | undefined;
  pageFragment: string;

  constructor(options: QuoteSummaryTableOptions) {
    super();
    this.eventTitle = options.title;
    this.quoteOwnerId = options.quoteOwnerId;
    this.quoteStates = options.quoteStates ?? QuoteState.None;
    this.customerCache = options.customerCache;
    this.userCache = options.userProfileCache;
    this.copyQuoteEvent = options.copyQuoteEvent;
    this.deleteQuoteEvent = options.deleteQuoteEvent;
    this.numberOfDaysHistory = options.numberOfDaysHistory;
    this.pageFragment = options.pageFragment;

    this.titleFilter = null;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewQuoteSummary>> {
    const results = await this.api.getQuoteSummary({
      numberOfDaysHistory: this.numberOfDaysHistory ?? null,
      quoteOwnerId: await this.quoteOwnerId(),
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      statesToLoad: this.quoteStates,
      title: this.titleFilter,
      description: null,
      sortField: request.sortField,
      sortAsc: request.sortAsc,
      assignedToUserId: this.assignedToUserId
    });
    if (!results) {
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };
    } else {
      //if there are any other callbacks we need to do, add them to the
      //all array which will let them run at the same time
      //pre fetch all linked data via a cache for displaying quickly reference information
      const pf = this.getPreFetched(results);
      await Promise.all(pf);

      return results.quoteSummary;
    }
  }
  async deleteQuoteItem(data: ViewQuoteSummary) {
    await this.deleteQuoteEvent?.(data);
  }
  async copyQuoteItem(data: ViewQuoteSummary) {
    await this.copyQuoteEvent?.(data);
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDefaultSortFieldIndex(columns: any[]): number {
    return columns.findIndex(c => c.data === 'lastModifiedDate');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): any[] {
    return [
      {
        title: tlang`%%quote%% No.`,
        width: '90px',
        data: 'quoteNumber',
        render: (value: number, _type, row: ViewQuoteSummary) =>
          this.quoteLink(row, value == 0 ? 'N/A' : value.toString())
      },
      {
        title: tlang`Title`,
        width: '200px',
        data: 'title',
        render: (value: string, _type: never, row: ViewQuoteSummary) => {
          return this.quoteLink(row, value);
        }
      },
      {
        title: tlang`Description`,
        width: '200px',
        data: 'description',
        render: (value: string, _type, row: ViewQuoteSummary) => this.quoteLink(row, value)
      },
      {
        title: tlang`"%%client%%"`,
        width: '150px',
        data: 'quoteCustomerId',
        render: (value: string) => this.getCustomerLink(value)
      },
      {
        title: tlang`Created By`,
        width: '150px',
        data: 'creationUserId',
        render: (value: string) => this.getUserDisplayValue(value)
      },
      {
        title: tlang`Modified`,
        width: '150px',
        data: 'lastModifiedDate',
        render: (value: string) => {
          const dt = new Date(value);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        }
      },
      {
        title: tlang`Gross Total`,
        width: '100px',
        data: 'calculatedGrossTotal',
        render: (data: number) => {
          return moneyToHtml(data);
        },
        className: 'dt-right'
      },
      {
        title: tlang`Net Total`,
        width: '100px',
        data: 'calculatedNetTotal',
        render: (data: number) => {
          return moneyToHtml(data);
        },
        className: 'dt-right'
      }
    ];
  }

  useAutoWidthColumns(): boolean {
    return true;
  }

  override enableFiltering(): boolean {
    return true;
  }

  override updateFilter(_searchTerm: string | null) {
    this.titleFilter = _searchTerm;
  }

  protected getPreFetched(results: ResultGetQuoteSummary): Promise<void>[] {
    const customerKeys = results.quoteSummary.results?.map(x => x.quoteCustomerId) ?? [];
    const userKeys = results.quoteSummary.results?.map(x => x.assignedToUserId) ?? [];

    return [this.customerCache.preFetch(customerKeys), this.userCache.preFetch(userKeys)];
  }

  protected quoteLink(row: ViewQuoteSummary, value: string) {
    return `<a class="quote-link" href="${resolveURL(
      resourceQuote,
      row.id
    )}" quoteid="${row.id}" >${this.htmlEncode(value)}</a>`;
  }

  protected getUserDisplayValue(value: string) {
    if (value == emptyGuid) return '';
    //prefetch should already have been called
    return this.userCache.getLocal(value)?.displayValue ?? '';
  }

  protected getCustomerLink(id: string) {
    if (id == emptyGuid) return '';
    //prefetch should already have been called
    const customer = this.customerCache.getLocal(id);
    return customer
      ? `<a class="client-link" href="${resolveURL(resourceClient, id)}" clientid="${id}" >${this.htmlEncode(
          customer.displayValue
        )}</a>`
      : '';
  }
}

export type QuoteViewFactory = (options: QuoteViewOptions) => QuoteView;

export interface QuoteListViewOptions {
  quoteOwnerId: EventGetReference;
  customerCache: DataCacheGeneric;
  userProfileCache: DataCacheGeneric;
}

//TODO.. there is a lot of generic potential in here to create a consistent PAGE control
// i think the page control code should be extrapolated into a page control template render
// that can bind events and content into the pages.
@customElement('wm-quotelistview')
export class QuoteListView extends ViewBase {
  protected tables: QuoteSummaryTable[];
  protected readonly quoteApi: QuoteApi = getApiFactory().quote();
  protected readonly quoteOwnerId: EventGetReference;
  protected readonly elementId: string;
  protected readonly customerCache: DataCacheGeneric;
  protected readonly userProfileCache: DataCacheGeneric;
  protected pageControl: PageControl;
  //propagated to quoteeditor
  protected readonly blobApi: BlobApi = getApiFactory().blob();
  protected readonly clientApi: ClientApi = getApiFactory().client();

  constructor(options: QuoteListViewOptions) {
    super();
    //TODO - maybe we want to inject an API.. but i dont think we need to
    this.elementId = `quote-list-view-${getInternalId()}`;
    this.quoteOwnerId = options.quoteOwnerId;
    this.customerCache = options.customerCache;
    this.userProfileCache = options.userProfileCache;

    this.tables = this.initializeTables();
    this.pageControl = this.createPageControl();
  }
  public async setActiveTabByHash() {
    await this.pageControl?.applyWindowHash();
  }
  userLoggedOff() {
    //force a refresh next time we log-in
    this.tables.forEach(t => (t.lastRefresh = new Date('2000-01-01')));
  }
  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      return this.tables.map(table => {
        const pm: PageManager = {
          caption: table.eventTitle,
          canClose: () => Promise.resolve(false),
          canLeave: () => Promise.resolve(true),
          hasDelete: () => false,
          onEnter: async () => {
            // not-awaiting on purpose, for faster page switch. safe to do
            table.refreshData(minutesToRefresh);
          },
          content: () => {
            return table;
          },
          data: table,
          pageFragment: table.pageFragment
        };
        return pm;
      });
    };
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: [
        {
          event: async () => {
            if (this.pageControl.activeTabIndex < this.tables.length) {
              const table = this.pageControl.activePage?.data as QuoteSummaryTable;
              await table.refreshData();
              return true;
            } else return false;
          },
          caption: () => html`${new IconRefresh()}`
        }
      ],
      plusPage: {
        caption: () => tlang`Create %%quote%%`,
        event: async () => await this.addNewQuote()
      },
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  protected async createNewQuote(): NullPromise<QuoteContainer> {
    //TODO-override this at another level
    return null;
  }

  async addNewQuote(): Promise<boolean> {
    const quoteContainer = await this.createNewQuote();
    if (quoteContainer && quoteContainer.quote) {
      return await launchQuote(quoteContainer.quoteId);
    }
    return false;
  }

  private async refreshTabsForState(state: number) {
    const res: Promise<void>[] = [];
    for (let i = 0; i < this.tables.length; i++) {
      const t = this.tables[i];
      if (t.quoteStates && state == state) {
        t.forceRefresh();
        res.push(t.refreshData());
        this.pageControl.requestUpdate();
      }
    }
    await Promise.allSettled(res);
  }
  private async updateAndRefreshQuoteTab(switchToActiveTab?: boolean) {
    if (switchToActiveTab) {
      if (this.pageControl.activeTabIndex !== 0) {
        this.tables[0].forceRefresh();
        await this.pageControl.setActiveTabIndex(0);
      } else this.tables[0].refreshData();
    } else {
      this.tables[this.pageControl.activeTabIndex].refreshData();
    }
  }

  async init() {
    //override me
  }

  //this can be overridden in sub class to replace the table with different column/view etc
  protected quoteSummaryTableFactory(options: QuoteSummaryTableOptions): QuoteSummaryTable {
    return new QuoteSummaryTable(options);
  }

  protected createSummaryTable(
    title: EventSnippet,
    quoteStates: QuoteState,
    pageFragment: string,
    numberOfDaysHistory: number | undefined
  ): QuoteSummaryTable {
    return this.quoteSummaryTableFactory({
      quoteOwnerId: this.quoteOwnerId,
      quoteStates: quoteStates,
      numberOfDaysHistory: numberOfDaysHistory,
      customerCache: this.customerCache,
      userProfileCache: this.userProfileCache,
      title: title,
      copyQuoteEvent: async (quoteSummary: ViewQuoteSummary) => {
        await this.copyQuote(quoteSummary);
      },
      deleteQuoteEvent: async (quoteSummary: ViewQuoteSummary) => {
        await this.deleteQuote(quoteSummary);
      },
      pageFragment: pageFragment
    });
  }

  //override this in a subclass to change the design, and order of the tables
  protected initializeTables(): QuoteSummaryTable[] {
    return [
      this.createSummaryTable(() => tlang`All !!quote!!`, QuoteState.None, 'allQuotes', undefined),
      this.createSummaryTable(() => tlang`Draft`, QuoteState.Draft, 'draft', undefined),
      this.createSummaryTable(
        () => tlang`Accepted`,
        QuoteState.Accepted | QuoteState.Accepted_AssignedToReviewer,
        'accepted',
        undefined
      ),
      this.createSummaryTable(() => tlang`Active`, QuoteState.Active, 'active', undefined),
      this.createSummaryTable(() => tlang`Issued`, QuoteState.Issued | QuoteState.IssuePending, 'issused', undefined),
      this.createSummaryTable(
        () => tlang`Rejected`,
        QuoteState.Rejected | QuoteState.Accepted_RefusedBySupplier,
        'rejected',
        30
      )
    ];
  }

  protected createQuoteContainer(quoteId: string): QuoteContainer {
    return new QuoteContainer(quoteId, null, null, null, null, null, null, null, null); //this is a delayed load item
  }
  protected async copyQuote(quoteSummary: ViewQuoteSummary): Promise<void> {
    const qcm = await this.createQuoteContainerManager(this.createQuoteContainer(quoteSummary.id));
    const copy = await qcm.makeCopy();
    if (copy) {
      await this.updateAndRefreshQuoteTab();
      launchQuote(copy.quoteId);
    }
  }

  protected createQuoteContainerManager(quoteContainer: QuoteContainer): QuoteContainerManager {
    return new QuoteContainerManager(quoteContainer, this.quoteApi, this.blobApi);
  }

  protected async deleteQuote(quoteSummary: ViewQuoteSummary): Promise<void> {
    const qcm = await this.createQuoteContainerManager(this.createQuoteContainer(quoteSummary.id));
    const deleted = (await qcm.deleteQuote()) ?? false;

    if (deleted) {
      await this.refreshTabsForState(quoteSummary.state);
      await this.doAfterQuoteEdit();
    }
  }

  doAfterQuoteEdit() {
    //override;
  }

  protected template(): EventTemplate {
    return html` <div id=${this.elementId} class="page-content">${this.pageControl.ui}</div>`;
  }
}
