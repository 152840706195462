import { getApiFactory } from '../../api/api-injector';
import { PurchaseOrderApi } from '../../api/purchase-order-api';
import { moneyToHtml } from '../../components/currency-formatter';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/datatable-view';
import { EventGetReference, EventSnippet } from '../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { PurchaseOrder, PurchaseOrderState, ResultGetPurchaseOrders } from '../../api/dealer-api-interface-franchisee';
import { render } from 'lit';
import { getPurchaseOrderStatus } from '../data/purchase-order-helper-functions';
import { resolveURL } from '../../components/ui/resource-resolver';
import { resourcePurchaseOrder } from '../ui/launcher';

export type EventPurchaseOrderOpen = (purchaseOrder: PurchaseOrder) => Promise<void>;

export interface PurchaseOrderSummaryTableOptions {
  purchaseOrderState?: PurchaseOrderState;
  numberOfDaysHistory?: number;
  title: EventSnippet;
  purchaseOrderOwnerId: EventGetReference;
  pageFragment: string;
}

export class PurchaseOrderSummaryTable extends DataTableWrapper<PurchaseOrder> {
  eventTitle: EventSnippet;
  purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();
  purchaseOrderOwnerId: EventGetReference;
  purchaseOrderState: PurchaseOrderState;
  titleFilter: string | null;
  numberOfDaysHistory: number | undefined;
  pageFragment: string;

  constructor(options: PurchaseOrderSummaryTableOptions) {
    super();

    this.purchaseOrderState = options.purchaseOrderState ?? PurchaseOrderState.None;
    this.eventTitle = options.title;
    this.purchaseOrderOwnerId = options.purchaseOrderOwnerId;
    this.titleFilter = null;
    this.numberOfDaysHistory = options.numberOfDaysHistory;
    this.pageFragment = options.pageFragment;
  }

  override enableFiltering(): boolean {
    return true;
  }

  override updateFilter(_searchTerm: string | null) {
    this.titleFilter = _searchTerm;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PurchaseOrder>> {
    const results = await this.purchaseOrderApi.getPurchaseOrders({
      purchaseOrderOwnerId: await this.purchaseOrderOwnerId(),
      statesToLoad: this.purchaseOrderState,
      numberOfDaysHistory: this.numberOfDaysHistory ?? null,
      filter: this.titleFilter,
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      sortField: request.sortField,
      sortAsc: request.sortAsc,
      createdById: null
    });

    if (!results) {
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };
    }

    await this.doPreFetching(results);

    return results.purchaseOrders;
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`System %%purchase-order-abrev%% No.`,
        width: '90px',
        data: 'purchaseOrderNumber',
        className: 'purchase-order-number',
        render: (value: number, _type, row: PurchaseOrder) =>
          this.getPurchaseOrderLink(row, value == 0 ? 'N/A' : value.toString())
      },
      {
        title: tlang`%%franchisee%% %%purchase-order-abrev%% No.`,
        width: '90px',
        data: 'customPurchaseOrderNumber',
        orderable: false,
        className: 'purchase-order-number',
        render: (_value: number, _type, row: PurchaseOrder) => {
          return row.customPurchaseOrderNumber;
        }
      },
      {
        title: tlang`%%purchase-order%% Title`,
        width: '200px',
        data: 'title',
        className: 'purchase-order-title',
        render: (value: string, _type: never, row: PurchaseOrder) => {
          return this.getPurchaseOrderLink(row, value);
        }
      },
      {
        title: tlang`Status`,
        width: '150px',
        data: 'state',
        orderable: false,
        className: 'purchase-order-state',
        render: (_value: number, _type, _row: PurchaseOrder) => '',
        createdCell: (cell: Node, cellData: number, _rowData: PurchaseOrder, _rowIndex: number, _colIndex: number) =>
          render(getPurchaseOrderStatus(cellData, true), cell as HTMLElement)
      },
      {
        title: tlang`Amount`,
        width: '100px',
        data: 'calculatedNetTotal',
        orderable: false,
        render: (_value: number, _type, row: PurchaseOrder) => {
          return moneyToHtml(row.calculatedNetTotal + row.calculatedAdjustmentTotal);
        },
        className: 'dt-right purchase-order-total'
      }
    ];
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDefaultSortFieldIndex(columns: any[]): number {
    return columns.findIndex(c => c.data === 'purchaseOrderNumber');
  }

  protected getPreFetched(_results: ResultGetPurchaseOrders): Promise<void>[] {
    return [];
  }

  protected getPurchaseOrderLink(row: PurchaseOrder, value: string) {
    return `<a class="purchase-order-link" href="${resolveURL(resourcePurchaseOrder, row.id)}" data-purchaseorderid="${row.id}" >${this.htmlEncode(value)}</a>`;
  }

  protected async doPreFetching(results: ResultGetPurchaseOrders) {
    await Promise.all(this.getPreFetched(results));
  }
}
