// eslint-disable-next-line import/named
import { html } from 'lit';
import { EventTemplate, Snippet } from '../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { QuoteSupplierDefaultsView, QuoteSupplierDefaultsViewOptions } from '../views/quote-supplier-defaults.view';
import { isValidV6ConfigVersion, v6Editors, v6SupportsVersion, v6VersionMap } from '../../v6config/v6config';
import { QuoteState } from '../../api/dealer-api-interface-quote';
import { DevelopmentError } from '../../development-error';
import { isFrame } from '../data/quote-helper-functions';
import { fromJsonStr } from '../../blob/converters';
import {
  QuoteDefaultsDataProvider,
  QuoteFrameItemMarker,
  ResultQuoteFrameItemUpdated,
  V6QuoteDefaultsEditor
} from '@softtech/webmodule-data-contracts';
import { information } from '../../components/ui/modal-option';

import { InformationDispatcher } from '../../components/ui/information-dispatcher';
import { SaveWorkflowModal } from '../../components/save-workflow';
import { isSupplierUsingSSI, updateQuoteItemContainer } from '../data/v6/helper-functions';
import { asMarkdownTemplate } from '../../components/markdown';

export class V6QuoteSupplierDefaultsView extends QuoteSupplierDefaultsView implements QuoteDefaultsDataProvider {
  v6editor: V6QuoteDefaultsEditor | null;
  get supplierReferenceOverrideKey(): string {
    return this.quoteManager.quote.quoteOwnerId;
  }
  constructor(options: QuoteSupplierDefaultsViewOptions) {
    super(options);
    this.v6editor = isValidV6ConfigVersion() ? v6Editors().getQuoteDefaultsEditorService(this) : null;
  }
  async updateQuoteItemsBegin(): Promise<boolean> {
    return true;
  }
  applyQuoteDefaultsHeaderTemplate() {
    const explanation = tlang`${'ref:WI221950-QuoteView-DiscardSupplierPriceAdjustmentOnGlobalOptionsChange:markdown'}
      ## Information

      Global option changes may result in changes to items with supplier price adjustments
      <webmodule-icon library="fa" name="fas-tags" class="text-base"></webmodule-icon>, if so, these adjustments will be discarded.
      You will then need to reactivate the associated support ticket to request a supplier review.`;

    return this.hasSupplierPriceAdjustments
      ? html` <div class="alert alert-info m-3" role="alert">${asMarkdownTemplate(explanation)}</div>`
      : undefined;
  }
  private get hasSupplierPriceAdjustments(): boolean {
    return this.quoteManager.container.itemPrices?.some(x => x.supplierPriceAdjustment !== 0) ?? false;
  }
  private async getSSIInputs() {
    const ssiProcessor = this.quoteManager.ssiProcessor();
    return await ssiProcessor.processSSI();
  }
  async updateQuoteItemsEnd(updatedCount: number): Promise<void> {
    if (updatedCount > 0) {
      if (!isSupplierUsingSSI(this.quote.supplierId)) return;

      const informationDispatcher = new InformationDispatcher();
      const setInfo = async (s: string, header = 'Preparing to update') => {
        await informationDispatcher.setInformation(`# ${header}
                + ${s}`);
      };
      await setInfo(tlang`getting updates from %%supplier%%`);
      const saveModal = new SaveWorkflowModal(tlang`Updating %%supplier%% !!quote-item!!`, informationDispatcher, 3);

      //we dont want to wait for the modal to finish.
      const modalState = await saveModal.show();
      await modalState.onShow;
      try {
        //process ssi with new information
        //build an ssi processor that will build the quote exluding the deleted item, so that we get data
        //based on its non-existence

        const ssiInputs = await this.getSSIInputs();
        await setInfo(tlang`%%supplier%% processing complete. 
                
                + sending updates to server`);
        if (ssiInputs && ssiInputs.items.length > 0) await this.quoteManager.saveAndUpdateQuoteItems(ssiInputs);
      } finally {
        saveModal?.done();
        await saveModal?.hideModal();
      }
    }
  }
  get readonly(): boolean {
    return this.v6editor === null || this.quoteManager.isReadonly();
  }
  get quoteIGUs(): unknown {
    return this.getQuoteIGUs();
  }
  async dispose(): Promise<void> {
    await super.dispose();
    //add nullable to deal with version missing info
    if (this.v6editor) this.v6editor.ui.remove();
    this.v6editor?.releaseResources?.();
    this.v6editor = null;
  }
  async quoteItems(): Promise<QuoteFrameItemMarker[]> {
    await this.quoteManager.needsQuoteItems();
    const frames = this.quoteManager.container.items
      ?.filter(x => isFrame(x))
      .map(x => {
        const container = this.quoteManager.quoteItemContainer(x.id);
        const qfd: QuoteFrameItemMarker = {
          id: x.id,
          description: container.item.title,
          qty: container.item.quantity,
          position: this.quoteManager.itemPosition(x.id) ?? -1,
          quoteItem: fromJsonStr(container.data?.providerData)
        };
        return qfd;
      });
    return frames ?? [];
  }

  async saveItem(frameData: ResultQuoteFrameItemUpdated): Promise<boolean> {
    const container = this.quoteManager.quoteItemContainer(frameData.id);
    const dataVer = container.data?.recordVersion ?? '';

    const buyInCost = await this.quoteManager.getBuyInCosts(frameData.quoteItem['buyIn']);

    //backwards compatilbity function
    //Todo, this can be removed after a suffient number of upgrades has occurred where
    //there is no doubt all systems are upgraded
    const ssiInputs = !isSupplierUsingSSI(this.quote.supplierId)
      ? undefined
      : !v6SupportsVersion(v6VersionMap.hasBeforeAfterApplyQuoteItemEvents)
        ? await this.getSSIInputs()
        : undefined;

    updateQuoteItemContainer(container, frameData.quoteItem, frameData.price, buyInCost);
    const newContainer = await this.quoteManager.saveAndUpdateQuoteItem(
      container,
      frameData.thumbnail ?? '',
      ssiInputs
    );
    return dataVer !== newContainer.data?.recordVersion ?? '';
  }

  getQuoteDefaults(): unknown {
    return this.getQuoteDefaultOptions();
  }
  saveQuoteDefaults(quoteDefaultsData: unknown): boolean {
    this.setQuoteDefaultOptions(quoteDefaultsData);
    return true;
  }

  protected getQuoteIGUs(): unknown {
    //TODO, get these from the quote manager
    return [];
  }
  protected canModify() {
    return this.quote.state == QuoteState.Draft || this.quote.state == QuoteState.Active;
  }

  protected getQuoteDefaultOptions(): unknown | null {
    return null;
  }
  protected setQuoteDefaultOptions(_options: unknown) {
    throw new DevelopmentError('setQuoteDefaultOptions must override');
  }

  async prepareForSave() {
    this.v6editor?.prepareForSave();
  }

  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    //override to do any asyn post construction work
  }

  protected template(): EventTemplate {
    return this.v6editor ? html`${this.v6editor?.ui}` : html`${tlang`%%supplier%% service not available`}`;
  }
  buttonMenu(): Snippet {
    const resetEvent = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      await this.v6editor?.resetQuoteDefaults();
    };
    const applyToItems = async (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.areItemsOpen()) {
        await information(tlang`Please close any open !!quote-item!! first`);
        return;
      }

      await this.prepareForSave();
      if (this.quoteManager.changed()) await this.quoteManager.saveQuote();
      await this.v6editor?.applyQuoteDefaultsToItems();
    };
    return this.v6editor
      ? html`
          <button class="btn secondary-btn" @click=${applyToItems} ?disabled=${!this.canModify()}>
            ${tlang`Apply To All Existing !!frame!!`}
          </button>
          <button class="btn secondary-btn" @click=${resetEvent} ?disabled=${!this.canModify()}>
            ${tlang`Reset
            Defaults`}
          </button>
        `
      : html``;
  }
}
