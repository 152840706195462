// eslint-disable-next-line import/named
import { html } from 'lit';
import { ProjectContainerManager } from '../data/project-container';
import { EventBooleanAsync, EventTemplate, Snippet } from '../../components/ui/events';
import { DataCacheGeneric } from '../../cache/generic-data-cache';
import { ViewBase } from '../../components/ui/view-base';
import { customElement } from 'lit/decorators.js';

export type EventResource = (id: string) => Promise<boolean>;

export interface ProjectResourceViewOptions {
  projectManager: ProjectContainerManager;
  quoteCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  canClose: EventBooleanAsync;
}
@customElement('wm-projectresourceview')
export class ProjectResourceView extends ViewBase {
  protected readonly projectManager: ProjectContainerManager;
  protected quoteCache: DataCacheGeneric;
  protected purchaseOrderCache: DataCacheGeneric;
  protected canClose: EventBooleanAsync;

  constructor(options: ProjectResourceViewOptions) {
    super();
    this.projectManager = options.projectManager;
    this.quoteCache = options.quoteCache;
    this.canClose = options.canClose;
    this.purchaseOrderCache = options.purchaseOrderCache;
  }

  public async invalidate(): Promise<void> {
    this.requestUpdate();
  }

  public async refreshData(): Promise<void> {
    throw new Error('No override provided for refresh data on resource view.');
  }

  public buttonMenu(): Snippet {
    return html``;
  }

  protected template(): EventTemplate {
    return html``;
  }

  public async hasPendingResources(): Promise<boolean> {
    return false;
  }

  public async updateResources(): Promise<void> {}
}
