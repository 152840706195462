import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';
import { emptyGuid } from '../../../api/guid';

import { Snippet } from '../../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { DashboardWidget } from './dashboard-widget';
import { PurchaseOrderPipelineTable } from '../data/purchase-order-pipeline-table';
import { PromiseSnippet } from '@softtech/webmodule-data-contracts';

@customElement('dashboard-branch-monthly-delivery-pipeline')
export class BranchMonthlyDeliveryPipeline extends DashboardWidget {
  @property() branchId?: string;
  @property() assignedToUserId?: string;
  @property() cutoff?: Date;

  private table?: PurchaseOrderPipelineTable;

  protected async getTable(): PromiseSnippet {
    if (this.table == undefined) {
      this.table = new PurchaseOrderPipelineTable({
        purchaseOrderOwnerId: async () => this.branchId ?? emptyGuid,
        assignedToUserId: async () => this.assignedToUserId ?? emptyGuid
      });
    } else {
      this.table.assignedToUserId = async () => this.assignedToUserId ?? emptyGuid;
      this.table.purchaseOrderOwnerId = async () => this.branchId ?? emptyGuid;
    }
    await this.table.refreshData();
    this.table?.invalidate();

    return this.table ? html`${this.table}` : html``;
  }

  protected getHeader(): Snippet {
    return tlang`Delivery Pipeline`;
  }

  protected getBody(): Snippet {
    return html` <div>${until(this.getTable(), html`${this.table}`)}</div> `;
  }
}
