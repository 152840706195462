import { ClientDetailView, ClientDetailViewOptions } from '../../../clients/views/client-detail-view';
import { PaymentProfileApi } from '../../../api/payment-profile-api';
import { ClientState } from '../../../api/dealer-api-interface-client';
// eslint-disable-next-line import/named
import { html } from 'lit';
import { FieldType } from '../../../components/ui/databinding/data-tracker';
import { PaymentProfilePicker } from '../../../franchisee/view/payment-profile-picker';
import '../../../components/ui/maps/google-place-autocomplete';
import '../../../components/ui/maps/google-map';
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../../api/api-injector';
import { FranchiseeClientContainerManager } from '../data/franchisee-client-container';
import { DevelopmentError } from '../../../development-error';
import { cache } from '../../cache/cache-registry';
import { DataCacheGeneric } from '../../../cache/generic-data-cache';
import { EventTemplate } from '../../../components/ui/events';
import { DataEntryPageControlView } from '../../../components/ui/data-entry-screen-base';
import { FormInputAssistant } from '../../../components/ui/templateresult/form-input-assistant';
import { AddressEditor } from '../../../components/ui/address-editor';
import { customElement } from 'lit/decorators.js';
@customElement('wm-franchiseeclientdetailview')
export class FranchiseeClientDetailView extends ClientDetailView {
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  paymentProfileCache: DataCacheGeneric = cache().paymentProfile;
  paymentProfileDisplay = '';

  constructor(owner: DataEntryPageControlView, options: ClientDetailViewOptions) {
    super(owner, options);

    if (!(options.clientContainerManager instanceof FranchiseeClientContainerManager))
      throw new DevelopmentError('FranchiseeClientDetailView only supports FranchiseeClientContainerManager');

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.client),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField(
      'paymentProfileId',
      FieldType.string,
      true,
      undefined,
      () => this.franchiseeClientManager.franchiseeClient
    );
  }

  protected get franchiseeClientManager(): FranchiseeClientContainerManager {
    return this.clientContainerManager as FranchiseeClientContainerManager;
  }

  public async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();
    await this.loadPickerDisplaysFromCache();
    this.requestUpdate();
  }

  public getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (!this.franchiseeClientManager.hasPaymentProfile()) errors.push(tlang`Please provide a %%payment-profile%%`);

    return errors;
  }

  protected async loadPickerDisplaysFromCache() {
    if (!this.franchiseeClientManager.hasPaymentProfile()) {
      this.paymentProfileDisplay = '';
    } else {
      const paymentProfile = await this.paymentProfileCache.get(
        this.franchiseeClientManager.franchiseeClient.paymentProfileId
      );
      this.paymentProfileDisplay = paymentProfile?.displayValue ?? '';
    }
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.clientContainerManager.isReadonly());
    const readonly = this.clientContainerManager.isReadonly();

    return html`
      <form class="frm-client-details form-two-col">
        <div class="row">
          <div>
            <h2>${tlang`%%client%% Details`}:</h2>
            ${forms.textRequired('name', tlang`Name`, 100)} ${forms.text('businessPhoneNumber', tlang`Phone`, 30)}
            ${forms.pickerRequired(
              'paymentProfileId',
              this.paymentProfileDisplay,
              async () => await this.SelectPaymentProfile(),
              tlang`%%payment-profile%%`
            )}
            ${forms.radioGroup('state', ClientState, tlang`Status`)}
          </div>

          <div>
            <h2>${tlang`Contact Details`}:</h2>
            ${this.primaryContactTemplate(forms)}
          </div>
        </div>

        <wm-addresseditor
          @wm-ae-changed=${(e: Event) => this.physicalAddressModified(e.currentTarget as AddressEditor)}
          id="physicalAddressEditor"
          .address=${this.client.physicalAddress}
          .shippingNotes=${this.client.shippingNotes ?? undefined}
          .title=${tlang`Physical Address`}
          .readonly=${readonly}
          .isDefaultShippingVisible=${true}
          .isDefaultShipping=${this.client.shipToPhysicalAddress}
        ></wm-addresseditor>
      </form>
    `;
  }

  private async SelectPaymentProfile() {
    const selectedProfile = await PaymentProfilePicker(this.paymentProfileApi);

    if (selectedProfile) {
      this.dataBinding.setValue('paymentProfileId', selectedProfile.id);
      this.paymentProfileDisplay = selectedProfile.name;
      this.requestUpdate();
    }
  }
}
