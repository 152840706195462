//Dealer Franchisee subclass views
import {
  QuoteState,
  ResultGetQuoteSummary,
  ViewQuoteSummary,
  ViewQuoteTotalByState
} from '../../../api/dealer-api-interface-quote';
import { emptyGuid } from '../../../api/guid';
import { NullPromise } from '../../../null-promise';
import { QuoteListView, QuoteSummaryTable, QuoteSummaryTableOptions } from '../../../quotes/views/quote-list-view';
import { QuoteContainer, QuoteContainerManager } from '../../../quotes/data/quote-container';
import { FranchiseeApi } from '../../../api/franchisee-api';
import { getApiFactory } from '../../../api/api-injector';
import { getCurrentUser, tlang } from '@softtech/webmodule-components';
import { isEmptyOrSpace, validId } from '../../../components/ui/helper-functions';
import { moneyToHtml, moneyToTemplateResult } from '../../../components/currency-formatter';
import { getQuoteNumberFormatted, getQuoteStatus } from '../../../quotes/data/quote-helper-functions';
import { html, render, TemplateResult } from 'lit';
import { getQuoteContainer, getQuoteContainerManager } from '../quote-ui-adapter';
import { cache } from '../../cache/cache-registry';
import { ItemReference } from '../../../cache/definitions/cache-item-reference';
import { QuoteCacheData } from '../../cache/cache-data';
import { ProjectResourceLink } from '../../cache/resource-link';
import { createNewQuote } from '../quote-creator';
import { getSupplier } from '../supplier-selector';
import { EventSnippet } from '../../../components/ui/events';

import { resourceProject } from '../../../projects/ui/launcher';
import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../components/ui/modal-confirmation';
import { resolveURL } from '../../../components/ui/resource-resolver';
import { EventBoolean } from '@softtech/webmodule-data-contracts';
import { userDataStore } from '../../common/current-user-data-store';
import { displaySupplierTACNotApprovedMsg, supplierOnline } from '../../../v6config/supplier-offline';
import { when } from 'lit/directives/when.js';
import { customElement, state } from 'lit/decorators.js';

const numberOfDaysHistory = 30;

@customElement('wm-franchiseequotesummarytable')
class FranchiseeQuoteSummaryTable extends QuoteSummaryTable {
  private projectCache = cache().project;
  private quoteCache = cache().quote;
  private linkCache = cache().projectResourceLink;
  private showAllQuotes = true;

  constructor(options: QuoteSummaryTableOptions) {
    super(options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDefaultSortFieldIndex(columns: any[]): number {
    return columns.findIndex(c => c.data === 'quoteNumber');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): any[] {
    const columns: any[] = [];
    columns.push({
      title: tlang`%%quote%% No.`,
      data: 'quoteNumber',
      render: (_value: number, _type, row: ViewQuoteSummary) => this.quoteLink(row, getQuoteNumberFormatted(row)),
      className: 'quote-number'
    });

    columns.push(
      ...[
        {
          title: tlang`%%quote%% Title`,
          data: 'title',
          render: (value: string, _type: never, row: ViewQuoteSummary) => {
            return this.quoteLink(row, value);
          },
          className: 'quote-title'
        },
        {
          title: tlang`%%project%% Title`,
          data: 'id',
          orderable: false,
          render: (value: string) => this.getProjectLink(value),
          className: 'quote-project-number'
        },
        {
          title: tlang`%%client%%`,
          data: 'quoteCustomerId',
          orderable: false,
          render: (value: string) => this.getCustomerLink(value),
          className: 'quote-client-name'
        },
        {
          title: tlang`%%author%%`,
          data: 'assignedToUserId',
          orderable: false,
          render: (value: string) => this.getUserDisplayValue(value),
          className: 'quote-created-by'
        },
        {
          title: tlang`Last Modified`,
          data: 'lastModifiedDate',
          orderable: false,
          render: (value: string) => {
            const dt = new Date(value);
            return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
          },
          className: 'quote-modified-date'
        },
        {
          title: tlang`Status`,
          data: 'state',
          orderable: false,
          render: (_value: number, _type, _row: ViewQuoteSummary) => '',
          createdCell: (
            cell: Node,
            cellData: number,
            _rowData: ViewQuoteSummary,
            _rowIndex: number,
            _colIndex: number
          ) => render(getQuoteStatus(cellData, true), cell as HTMLElement),
          className: 'quote-status'
        },
        {
          title: tlang`Net Amount`,
          data: 'calculatedNetTotal',
          orderable: false,
          render: (value: number, _type, _row: ViewQuoteSummary) => moneyToHtml(value),
          className: 'dt-right quote-amount'
        },
        {
          title: '<webmodule-icon library="fa" name="fas-bars"></webmodule-icon>',
          data: 'id',
          orderable: false,
          render: (_value: number, _type, row: ViewQuoteSummary) => {
            return this.ellipsisMenu(row);
          },
          className: 'item-menu'
        }
      ]
    );
    return columns;
  }

  override enableAdvancedFiltering(): boolean {
    return true;
  }

  override advancedFilterTemplate(): TemplateResult {
    const createRadioButton = (id: string, text: string, input: boolean, value: EventBoolean) => {
      console.log(`createRadioButton->value:${value()}`);
      return html`
        <softtech-dui-input-boolean
          id=${id}
          @click=${_ => this.onQuoteAuthorChanged(input)}
          .readonly=${value()}
          .checked=${value()}
        ></softtech-dui-input-boolean>
        <label class="form-check-label" for=${id}> ${text} </label>`;
    };

    return html`
      <div class="form-check form-check-inline quote-author-filter">
        ${createRadioButton('qte-list-show-all', tlang`All !!quote!!`, true, () => this.showAllQuotes)}
        ${createRadioButton('qte-list-show-mine', tlang`My !!quote!!`, false, () => !this.showAllQuotes)}
      </div>`;
  }

  ellipsisMenu(quoteSummary: ViewQuoteSummary): string {
    return `
    <webmodule-dropdown placement="bottom-end" hoist>
      <webmodule-icon-button slot="trigger" library="fa" name="fas-bars"></webmodule-icon-button>
      <webmodule-menu>
        ${when(
      !(quoteSummary.state == QuoteState.Draft),
      () => '',
      () => `<webmodule-menu-item class="action-delete">
          <webmodule-icon slot="prefix" library="fa" name="fas-trash"></webmodule-icon>
              Delete
        </webmodule-menu-item>`
    )}

        <webmodule-menu-item class="action-copy">
          <webmodule-icon slot="prefix" library="fa" name="fas-copy"></webmodule-icon>
          Copy
        </webmodule-menu-item>
      </webmodule-menu>
    </webmodule-dropdown>`;
  }

  bindClickEvents($dataTable: any): void {
    super.bindClickEvents($dataTable);
    $dataTable.on(
      'click',
      '.action-copy',
      this.eventHandler(async (data: ViewQuoteSummary) => {
        await this.copyQuoteItem(data);
      })
    );
    $dataTable.on(
      'click',
      '.action-delete',
      this.eventHandler(async (data: ViewQuoteSummary) => {
        if (
          await AskConfirmation(
            tlang`Are you sure you'd like to delete the %%quote%%?`,
            confirmationButtons[ConfirmationButtonType.yesNo]
          )
        )
          await this.deleteQuoteItem(data);
      })
    );
  }

  protected getPreFetched(results: ResultGetQuoteSummary): Promise<void>[] {
    const cachePrefetch = super.getPreFetched(results);

    const updateFromSummaries: ((items: ViewQuoteSummary[]) => Promise<ItemReference<QuoteCacheData>[]>) | undefined = (
      this.quoteCache as any
    ).updateFromSummaries.bind(this.quoteCache);
    if (updateFromSummaries) {
      //this is the optimized efficient method
      const p = updateFromSummaries(results.quoteSummary.results).then(items => {
        const quoteIds = items.map(x => x.id);

        return this.linkCache.preFetch(quoteIds).then(() => {
          const projectIds: string[] = [];
          results.quoteSummary.results.forEach(summary => {
            const projectId = this.linkCache.getLocalData(summary.id)?.projectId ?? emptyGuid;
            if (validId(projectId)) projectIds.push(projectId);
          });
          return this.projectCache.preFetch(projectIds);
        });
      });

      cachePrefetch.push(p);
    } else {
      //less optimized fallback
      const quoteIds = results.quoteSummary.results.map(x => x.id);

      cachePrefetch.push(
        this.quoteCache
          .preFetch(quoteIds)
          .then(() => {
            return this.linkCache.getMany(quoteIds);
          })
          .then((data: ItemReference<ProjectResourceLink>[] | null) => {
            const projectIds = data?.map(x => x.data.projectId) ?? [];
            return this.projectCache.preFetch(projectIds);
          })
      );
    }
    return cachePrefetch;
  }

  protected async onQuoteAuthorChanged(value: boolean) {
    this.showAllQuotes = value;
    this.assignedToUserId = this.showAllQuotes ? null : getCurrentUser()?.id ?? null;

    await this.refreshData();
    this.requestUpdate();
  }

  protected getProjectLink(id: string) {
    if (id == emptyGuid) return '';

    const link = this.linkCache.getLocalData(id);

    if (!link) return '';

    const project = this.projectCache.getLocal(link.projectId);

    return project
      ? `<a class="project-link" href="${resolveURL(
        resourceProject,
        project.id
      )}" data-projectid="${project.id}" >${this.htmlEncode(project.data.projectSummary.title)}</a>`
      : '';
  }
}

@customElement('wm-franchiseequotelistview')
export class FranchiseeQuoteListView extends QuoteListView {
  franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
  @state()
  quoteTotals: ViewQuoteTotalByState[] | null = null;

  async init() {
    await this.updatePageTotals();
    const table = this.pageControl.activePage?.data as QuoteSummaryTable;
    if (table) await table.refreshData(1);
  }

  async doAfterQuoteEdit() {
    await this.updatePageTotals();
  }

  protected createQuoteContainerManager(quoteContainer: QuoteContainer): QuoteContainerManager {
    return getQuoteContainerManager(quoteContainer);
  }

  protected async updatePageTotals() {
    const ownerId = await this.quoteOwnerId();
    const totals = await this.quoteApi.getQuoteTotalsByState({
      quoteOwnerId: ownerId,
      options: [
        {
          state: QuoteState.Draft | QuoteState.Active,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Draft,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Active,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Issued | QuoteState.IssuePending,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Accepted,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.Accepted_AssignedToReviewer | QuoteState.SupplierReviewPending,
          numberOfDaysHistory: null
        },
        {
          state: QuoteState.SupplierReviewed,
          numberOfDaysHistory: null
        },
        {
          state:
            QuoteState.Approved |
            QuoteState.Rejected |
            QuoteState.Accepted_RefusedBySupplier |
            QuoteState.Cancelled |
            QuoteState.Lapsed,
          numberOfDaysHistory: numberOfDaysHistory
        }
      ]
    });
    if (totals) this.quoteTotals = totals.totals.filter(x => x.quoteOwnerId === ownerId);
    this.pageControl.requestUpdate();
  }

  protected createQuoteContainer(quoteId: string): QuoteContainer {
    return getQuoteContainer(quoteId);
  }

  //this can be overridden in sub class to replace the table with different column/view etc
  protected quoteSummaryTableFactory(options: QuoteSummaryTableOptions): FranchiseeQuoteSummaryTable {
    return new FranchiseeQuoteSummaryTable(options);
  }

  protected createSummaryTable(
    title: EventSnippet,
    quoteStates: QuoteState,
    pageFragment: string,
    numberOfDays?: number
  ): QuoteSummaryTable {
    return this.quoteSummaryTableFactory({
      quoteOwnerId: this.quoteOwnerId,
      quoteStates: quoteStates,
      numberOfDaysHistory: numberOfDays,
      customerCache: this.customerCache,
      userProfileCache: this.userProfileCache,
      title: title,
      copyQuoteEvent: async (quoteSummary: ViewQuoteSummary) => {
        await this.copyQuote(quoteSummary);
      },
      deleteQuoteEvent: async (quoteSummary: ViewQuoteSummary) => {
        await this.deleteQuote(quoteSummary);
      },
      pageFragment: pageFragment
    });
  }

  //override this in a subclass to change the design, and order of the tables
  protected initializeTables(): QuoteSummaryTable[] {
    const title = (caption: string, state: QuoteState, hideTotal?: boolean) => {
      let total = 0;
      let count = 0;
      this.quoteTotals
        ?.filter(x => (state & x.quoteState) === x.quoteState)
        .forEach(x => {
          total += x.total;
          count += x.quoteCount;
        });
      return html`${caption} (${count})${hideTotal ? html`` : moneyToTemplateResult(total)}`;
    };
    const makeTable = (
      titleStr: string,
      state: QuoteState,
      pageFragment: string,
      numberOfDays?: number,
      hideTotal?: boolean
    ) => {
      return this.createSummaryTable(() => title(titleStr, state, hideTotal), state, pageFragment, numberOfDays);
    };

    return [
      makeTable(tlang`All Current`, QuoteState.Draft | QuoteState.Active, 'allCurrent', undefined, true),
      makeTable(tlang`Draft`, QuoteState.Draft, 'draft', undefined, true),
      makeTable(tlang`Active`, QuoteState.Active, 'active', undefined, true),

      makeTable(tlang`Issued`, QuoteState.Issued | QuoteState.IssuePending, 'issued', undefined, true),

      makeTable(tlang`Accepted`, QuoteState.Accepted, 'accepted', undefined, true),
      makeTable(
        tlang`Under Review`,
        QuoteState.Accepted_AssignedToReviewer | QuoteState.SupplierReviewPending,
        'underreview',
        undefined,
        true
      ),
      makeTable(tlang`Reviewed`, QuoteState.SupplierReviewed, 'reviewed', undefined, true),

      makeTable(
        tlang`Archived`,
        QuoteState.Approved |
        QuoteState.Rejected |
        QuoteState.Accepted_RefusedBySupplier |
        QuoteState.Cancelled |
        QuoteState.Lapsed,
        'archived',
        numberOfDaysHistory,
        true
      )
    ];
  }

  protected async createNewQuote(): NullPromise<QuoteContainer> {
    //flag as online, even if we are not
    //because any time we try this activity, we want it to force and offline msg.
    supplierOnline();
    const supplier = await getSupplier();
    if (isEmptyOrSpace(supplier.supplierId)) return null;
    if (!userDataStore.supplierTACApproved(supplier.supplierId)) {
      await displaySupplierTACNotApprovedMsg(supplier, true);
      return null;
    }
    const owner = await this.quoteOwnerId();

    return createNewQuote({supplierId: supplier.supplierId, owner: owner});
  }
}
