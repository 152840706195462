/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { moneyToHtml } from '../../../components/currency-formatter';
import { tlang } from '@softtech/webmodule-components';
import {
  PurchaseOrderSummaryTable,
  PurchaseOrderSummaryTableOptions
} from '../../../purchase-orders/views/purchase-order-summary-table';

import { PurchaseOrder, ResultGetPurchaseOrders } from '../../../api/dealer-api-interface-franchisee';
import { cache } from '../../cache/cache-registry';
import {
  getPurchaseOrderNumberFormatted,
  getPurchaseOrderStatus
} from '../../../purchase-orders/data/purchase-order-helper-functions';
import { render } from 'lit';
import { resolveURL } from '../../../components/ui/resource-resolver';
import { resourceQuote } from '../../../quotes/ui/launcher';
import { resourceProject } from '../../../projects/ui/launcher';
import { emptyGuid } from '../../../api/guid';
import { customElement } from 'lit/decorators.js';

@customElement('wm-franchiseepurchaseordersummarytable')
export class FranchiseePurchaseOrderSummaryTable extends PurchaseOrderSummaryTable {
  purchaseOrderCache = cache().purchaseOrder;
  quoteCache = cache().quote;
  projectCache = cache().project;
  prlCache = cache().projectResourceLink;
  userCache = cache().userProfile;

  constructor(options: PurchaseOrderSummaryTableOptions) {
    super(options);
  }

  protected override getPreFetched(results: ResultGetPurchaseOrders): Promise<void>[] {
    const basePreFetch = super.getPreFetched(results);

    const purchaseOrderKeys = results.purchaseOrders.results?.map(x => x.id) ?? [];

    const purchaseOrderCachePromise = this.purchaseOrderCache.preFetch(purchaseOrderKeys);

    basePreFetch.push(purchaseOrderCachePromise);

    const userKeys = results.purchaseOrders.results?.map(x => x.assignedToUserId);
    const userProfilesPreFetch = this.userCache.preFetch(userKeys);
    basePreFetch.push(userProfilesPreFetch);

    return basePreFetch;
  }

  protected override async doPreFetching(results: ResultGetPurchaseOrders): Promise<void> {
    await super.doPreFetching(results);

    const poKeys = results.purchaseOrders.results.map(x => x.id);
    await this.purchaseOrderCache.getMany(poKeys);
    const links = await this.prlCache.getMany(poKeys);

    const quoteKeys = links?.map(x => x.data.quoteId) ?? [];
    const projectKeys = links?.map(x => x.data.projectId) ?? [];

    await Promise.all([this.quoteCache.preFetch(quoteKeys), this.projectCache.preFetch(projectKeys)]);
  }

  private getQuoteLink(po: PurchaseOrder): string {
    const prl = this.prlCache.getLocalData(po.id);
    const quoteTitle = prl ? this.quoteCache.getLocalData(prl.quoteId)?.quoteSummary.title ?? '' : '';

    return prl
      ? `<a class="quote-link" href="${resolveURL(resourceQuote, prl.quoteId)}" data-quote-id="${prl?.quoteId}" >${this.htmlEncode(quoteTitle)}</a>`
      : `<span class="quote-link" >${this.htmlEncode(quoteTitle)}</span>`;
  }

  private getProjectLink(po: PurchaseOrder): string {
    const prl = this.prlCache.getLocalData(po.id);
    const title = prl ? this.projectCache.getLocalData(prl.projectId)?.projectSummary.title ?? '' : '';

    return prl
      ? `<a class="project-link" href="${resolveURL(resourceProject, prl.projectId)}" data-project-id="${prl.projectId}" >${this.htmlEncode(title)}</a>`
      : `<span class="project-link"  >${this.htmlEncode(title)}</span>`;
  }

  protected override bindClickEvents($dataTable: any) {
    super.bindClickEvents($dataTable);
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`System %%purchase-order-abrev%% No.`,
        width: '90px',
        data: 'purchaseOrderNumber',
        className: 'system-purchase-order-number',
        render: (_value: number, _type, row: PurchaseOrder) =>
          this.getPurchaseOrderLink(row, getPurchaseOrderNumberFormatted(row))
      },
      {
        title: tlang`%%franchisee%% %%purchase-order-abrev%% No.`,
        width: '90px',
        data: 'customPurchaseOrderNumber',
        className: 'dealership-purchase-order-number',
        render: (_value: number, _type, row: PurchaseOrder) => {
          return row.customPurchaseOrderNumber;
        }
      },
      {
        title: tlang`%%purchase-order%% Title`,
        width: '150px',
        data: 'title',
        className: 'purchase-order-title',
        render: (_value: string, _type, row: PurchaseOrder) => this.getPurchaseOrderLink(row, _value)
      },
      {
        title: tlang`%%project%% Title`,
        width: '100px',
        data: 'id',
        orderable: false,
        className: 'purchase-order-project-title',
        render: (_value: string, _type: never, row: PurchaseOrder) => {
          return this.getProjectLink(row);
        }
      },
      {
        title: tlang`%%quote%% Title`,
        width: '100px',
        data: 'id',
        orderable: false,
        className: 'purchase-order-quote-title',
        render: (_value: string, _type: never, row: PurchaseOrder) => {
          return this.getQuoteLink(row);
        }
      },
      {
        title: tlang`%%author%%`,
        width: '100px',
        data: 'assignedToUserId',
        orderable: false,
        className: 'purchase-order-author',
        render: (value: string, _type: never, _row: PurchaseOrder) => {
          return this.getUserDisplayValue(value);
        }
      },
      {
        title: tlang`Last Modified`,
        width: '50px',
        data: 'lastModifiedDate',
        className: 'purchase-order-last-modified-date',
        orderable: false,
        render: (_value: string, _type: never, row: PurchaseOrder) => {
          const dt = new Date(row.lastModifiedDate ?? row.dateCreated);
          return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        }
      },
      {
        title: tlang`Status`,
        width: '150px',
        data: 'state',
        orderable: false,
        className: 'purchase-order-state',
        render: (_value: number, _type, _row: PurchaseOrder) => '',
        createdCell: (cell: Node, cellData: number, _rowData: PurchaseOrder, _rowIndex: number, _colIndex: number) =>
          render(getPurchaseOrderStatus(cellData, true), cell as HTMLElement)
      },
      {
        title: tlang`Net Cost`,
        width: '100px',
        data: 'calculatedNetTotal',
        orderable: false,
        render: (_value: number, _type, row: PurchaseOrder) => {
          return moneyToHtml(row.calculatedNetTotal + row.calculatedAdjustmentTotal);
        },
        className: 'dt-right purchase-order-total'
      }
    ];
  }

  protected getUserDisplayValue(value: string) {
    if (value == emptyGuid) return '';
    //prefetch should already have been called
    return this.userCache.getLocal(value)?.displayValue ?? '';
  }
}
