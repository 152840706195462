// eslint-disable-next-line import/named
import { html, PropertyValueMap } from 'lit';
import { LineItemView } from '../../../quotes/views/line-item-view';
import { getQuoteItemTab, isSSI } from '../../../quotes/data/quote-helper-functions';
import { QuoteItemType } from '../../../api/dealer-api-interface-quote';
import { DevelopmentError } from '../../../development-error';
import { quoteItemContentType } from '../../../quotes/data/default-quote-item-content-type';
import { getCurrentUser, tlang } from '@softtech/webmodule-components';
import { Snippet } from '@softtech/webmodule-data-contracts';
import { emptyGuid, newGuid } from '../../../api/guid';
import { getApiFactory } from '../../../api/api-injector';
import {
  BranchQuoteSupport,
  BranchQuoteSupportItemType,
  BranchQuoteSupportStatus,
  QuoteItemConversation,
  QuoteItemConversationType,
  ViewConversationEntry
} from '../../../api/dealer-api-interface-franchisee';

import { ETOEditorSavedEventData, WebModuleFranchiseeETOEditor } from './franchisee-engineered-to-order';

import { userDataStore } from '../../common/current-user-data-store';
import { firstValidString, isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { nowServer } from '../../../components/datetime-converter';
import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { customElement, query } from 'lit/decorators.js';
import { EventTemplate } from '../../../components/ui/events';

/**
 *
 * This is the new format Engineered To Order item,
 * which blends the basic special item, with the new
 * ETO Request
 */
@customElement('wm-engineeredtoorderlineitemview')
export class EngineeredToOrderLineItemView extends LineItemView {
  protected branchQuoteSupport?: BranchQuoteSupport;
  protected masterDocument?: ViewConversationEntry;
  protected franchiseeApi = getApiFactory().franchisee();
  protected quoteItemConversationLink?: QuoteItemConversation | null;
  protected dataPrimaryKey = newGuid();
  get qcm(): FranchiseeQuoteContainerManager {
    return this.quoteManager as FranchiseeQuoteContainerManager;
  }
  get forceReadonly() {
    return this.quoteManager.isReadonly();
  }

  get quoteSetId() {
    return this.quoteManager.quote.quoteSetId;
  }

  get branchQuoteSupportId(): string {
    return this.branchQuoteSupport?.id ?? this.quoteItemContainer?.item.id ?? emptyGuid;
  }

  public get hasPropertyDialog(): boolean {
    //if (this.quoteItemContainer && isSSI(this.quoteItemContainer.item))
    return true;
    //return false;
  }

  public get hasModalEditDialog(): boolean {
    return false;
  }

  get quoteId() {
    return this.quoteManager.quoteId;
  }
  @query('#editor')
  etoEditor?: WebModuleFranchiseeETOEditor;

  override isDataReadonly(): boolean {
    return (
      super.isDataReadonly() ||
      this.branchQuoteSupport?.status == BranchQuoteSupportStatus.Resolved ||
      this.branchQuoteSupport?.status == BranchQuoteSupportStatus.Cancelled
    );
  }

  buttonMenu(): Snippet {
    return this.etoEditor
      ? html` ${this.abandonAndCloseButton()} ${this.etoEditor.branchSupportActionsTemplate()} `
      : html``;
  }
  protected async firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): Promise<void> {
    await super.firstUpdated(_changedProperties);
    if (this.etoEditor) this.requestUpdate();
  }
  discardLabel(): unknown {
    if (this.isDataReadonly()) return tlang`Close`;

    return this.isNew() ? tlang`Discard` : tlang`Discard Changes`;
  }

  public async loadBranchQuoteSupport(quoteItemId: string): Promise<boolean> {
    //copied ETO use the same conversation
    const supportData = await this.qcm.needsQuoteSupport(true); //always reload a fresh copy

    this.quoteItemConversationLink = await this.franchiseeApi.quoteItemConversationLink({
      quoteId: this.quoteManager.quote.id,
      quoteItemId: quoteItemId,
      type: QuoteItemConversationType.EngineeredToOrder,
      conversationId: null
    });
    /*
    this.quoteItemConversationLink = supportData?.conversationLinks.find(
      x => x.quoteItemId === quoteItemId && x.type === QuoteItemConversationType.EngineeredToOrder
    );
*/
    if (!this.quoteItemConversationLink) return false;

    this.branchQuoteSupport = supportData?.items.find(
      x => x.conversationId === this.quoteItemConversationLink?.conversationId
    );
    if (!this.branchQuoteSupport) return false;
    this.masterDocument = supportData?.masterDocuments.find(
      x => x.id === this.branchQuoteSupport?.masterDocumentConversationId
    );

    if (this.branchQuoteSupport && !this.branchQuoteSupport.masterDocumentConversationId) {
      this.branchQuoteSupport.masterDocumentConversationId = newGuid();

      this.masterDocument = (
        await this.franchiseeApi.addConversationEntry({
          attachments: [],
          branchQuoteSupportId: null,
          conversationEntryId: this.branchQuoteSupport.masterDocumentConversationId,
          conversationId: this.branchQuoteSupport.masterDocumentConversationId,
          draft: false,
          text: 'MASTERDOCUMENT',
          users: []
        })
      )?.conversationEntry;

      //deal with pre-update code. we can remove this down the line
      const newResult = await this.franchiseeApi.updateBranchQuoteSupport({
        branchQuoteId: this.quoteId,
        branchQuoteSupportId: this.branchQuoteSupport.id,
        masterDocumentConversationId: this.branchQuoteSupport.masterDocumentConversationId,
        status: null,
        subject: null,
        quoteItemIdToDetach: null,
        conversationEntries: null
      });
      this.branchQuoteSupport = newResult?.branchQuoteSupport;
      this.masterDocument = newResult?.masterDocument ?? undefined;
    }
    return this.branchQuoteSupport !== undefined && this.masterDocument != undefined;
  }

  //true then this page is not valid, and should be assumed to be cancelled
  public async prepareEditor(): Promise<void> {
    if (this.quoteItemContainer) {
      if (isSSI(this.quoteItemContainer.item)) {
        this._readyToEdit = false;
        return;
      }

      this._readyToEdit = true;
      this.dataPrimaryKey = this.quoteItemContainer.item.id;

      if (!(await this.loadBranchQuoteSupport(this.dataPrimaryKey))) {
        const transactionDate = nowServer();
        const userId = getCurrentUser()?.id ?? emptyGuid;
        this.createMockBranchQuoteSupport(transactionDate, userId);
      }
    } else {
      this._readyToEdit = true;
      this.createMockQuoteItem();
    }

    if (!this.quoteItemContainer) return;
    if (this.quoteItemContainer.item.itemType !== QuoteItemType.Basic)
      throw new DevelopmentError(
        `special-line-item-view, ${this.quoteItemContainer.item.description} is not a special line item.`
      );
    if (this.quoteItemContainer.item.quoteItemContentType !== quoteItemContentType.specialItem)
      throw new DevelopmentError(`quote item ${this.quoteItemContainer.item.title} is not a special line item`);
    this.requestUpdate();
  }

  public isTab(): boolean {
    return (this.quoteItemContainer && !isSSI(this.quoteItemContainer.item)) ?? false;
  }

  async prepareForSave(): Promise<void> {
    if (this.forceReadonly) return;
    this.etoEditor?.prepareForSave();
  }

  public internalDataChanged(): boolean {
    return this.etoEditor?.hasDataChanged ?? false;
  }

  createMockQuoteItem() {
    const transactionDate = nowServer();
    const userId = getCurrentUser()?.id ?? emptyGuid;
    this.quoteItemContainer = {
      item: {
        comment: '',
        commonId: newGuid(),
        dateCreated: transactionDate,
        description: '',
        id: this.dataPrimaryKey,
        isRestrictedToPowerUser: false,
        itemType: QuoteItemType.Basic,
        lastModifiedDate: transactionDate,
        lastModifiedUserId: userId,
        providerReferenceId: emptyGuid,
        quantity: 1,
        quoteId: this.quoteManager.quoteId,
        quoteItemContentType: quoteItemContentType.specialItem,
        recordVersion: '',
        serviceProvider: '',
        title: tlang`New %%special-item%%`,
        virtualThumbnailPath: ''
      },
      price: {
        calculatedGrossSellingPrice: 0,
        calculatedNetSellingPrice: 0,
        dateCreated: transactionDate,
        id: this.dataPrimaryKey,
        isTaxableItem: true,
        marginPercentage: null,
        priceAdjustment: 0,
        quantityCost: 0,
        recordVersion: '',
        requiresRepricing: false,
        singleUnitCost: 0,
        sourceData: {},
        supplierGrossQuantityCost: 0,
        supplierGrossSingleUnitCost: 0,
        supplierNettQuantityCost: 0,
        supplierNettSingleUnitCost: 0,
        supplierPriceAdjustment: 0
      },
      buyInData: null,
      data: null,
      dataObject: null
    };
    this.createMockBranchQuoteSupport(transactionDate, userId);
  }

  protected isNew(): boolean {
    return !this.quoteItemContainer || isEmptyOrSpace(this.quoteItemContainer.item.recordVersion);
  }

  protected name() {
    return 'engineered-to-order';
  }

  protected getValidationErrors(): string[] {
    return this.etoEditor?.getValidationErrors() ?? [];
  }

  protected async revertChanges(): Promise<boolean> {
    return (await this.etoEditor?.revertChanges()) ?? true;
  }

  protected getCaption(): Snippet {
    return getQuoteItemTab(
      firstValidString(this.quoteItemContainer?.item.title, tlang`%%special-item%%`),
      this.quoteManager.itemPosition(this.quoteItemContainer?.item.id)
    );
  }

  protected async internalSaveData(): Promise<boolean> {
    return (await this.etoEditor?.save()) ?? false;
  }

  protected eventETOSaved = (e: CustomEvent<ETOEditorSavedEventData>) => {
    this.quoteItemContainer = e.detail.quoteItemContainer;
    this.branchQuoteSupport = e.detail.branchQuoteSupport;
    this.masterDocument = e.detail.masterDocument;
    this.quoteItemConversationLink = e.detail.quoteItemConversationLink;

    this.refreshParent();
  };

  protected eventEtoStateChanged = () => {
    this.refreshParent();
  };

  protected bodyTemplate(): EventTemplate {
    return html` <wmview-franchisee-eto-editor
      id="editor"
      @wm-event-saved="${this.eventETOSaved}"
      @wm-event-changed="${this.eventEtoStateChanged}"
      .quoteItemContainer="${this.quoteItemContainer ?? undefined}"
      .branchQuoteSupport="${this.branchQuoteSupport}"
      .masterDocument="${this.masterDocument}"
      .quoteManager="${this.qcm}"
      .isNewETO="${this.isNew()}"
      .quoteItemConversationLink="${this.quoteItemConversationLink}"
    ></wmview-franchisee-eto-editor>`;
  }

  private createMockBranchQuoteSupport(transactionDate: string, userId: string) {
    if (!this.quoteItemContainer) throw new DevelopmentError('ETO QuoteItemContainer cannot be null');
    const masterDocumentId = newGuid();
    this.branchQuoteSupport = {
      branchQuoteId: this.quoteId,
      conversationId: this.quoteItemContainer.item.id,
      dateCreated: transactionDate,
      id: this.quoteItemContainer.item.id,
      lastModified: transactionDate,
      lastModifiedUserId: userId,
      createdUserId: userId,
      masterDocumentConversationId: masterDocumentId,
      recordVersion: '',
      status: BranchQuoteSupportStatus.Draft,
      subject: this.quoteItemContainer.item.title,
      type: BranchQuoteSupportItemType.EngineeredToOrder
    };
    this.masterDocument = {
      attachments: [],
      conversationId: masterDocumentId,
      dateCreated: transactionDate,
      draft: false,
      editCount: 0,
      franchiseeId: userDataStore.franchisee.id,
      id: masterDocumentId,
      lastModified: transactionDate,
      systemUserId: userId,
      text: 'MASTERDOCUMENT',
      users: []
    };
    this.quoteItemConversationLink = {
      dateCreated: transactionDate,
      id: newGuid(),
      recordVersion: '',
      conversationId: this.quoteItemContainer.item.id,
      quoteItemId: this.quoteItemContainer.item.id,
      quoteId: this.quoteManager.quote.id,
      type: QuoteItemConversationType.EngineeredToOrder
    };
  }
}
